import React, { useEffect } from 'react';
import style from './GetOrderForms.module.css'
import { collection, getDocs, orderBy, query, } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useState } from 'react';


export const GetPeopleCleaningCollection = () => {

    const [data, setData] = useState([])

    const getData = async () => {

        const form = query(collection(db, "PeopleServicesCleaning"), orderBy('date', "desc"))
        const querySnapshot = await getDocs(form);

        const docs = []
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            docs.push(doc.data())

        });
        setData(docs)
    }
    useEffect(() => {
        getData()
    }, [])

    const timeString = (exp) => {
        switch (exp) {
            case "year":
                exp = 'От года'
                break;
            case "3years":
                exp = 'От 3 лет'
                break;
            case "never":
                exp = 'Никогда'
                break;
        }

    }

    return (
        <div>
            <h3 className={style.title}>От жителей Клининг</h3>

            <table className={style.table}>
                <tr>
                    <th>Дата</th>
                    <th>Район</th>
                    <th>ЖК</th>
                    <th>Адрес</th>
                    <th>Парадная</th>
                    <th>Этаж</th>
                    <th>Кол-во окон</th>
                    <th>Окно</th>
                    <th>Балкон</th>
                    <th>Послестрой</th>
                    <th>Когда мыли</th>
                    <th>Цена</th>
                    <th>Имя</th>
                    <th>Телефон</th>
                </tr>

                {data.map((item) => (
                    <tr key={item.id} >
                        <td style={{ textAlign: 'center' }}>{item.date}</td>
                        <td>{item.region}</td>
                        <td style={{ textAlign: 'center' }}>{item.residentArea}</td>
                        <td>{item.address}</td>
                        <td style={{ textAlign: 'center' }}>{item.frontDoor}</td>
                        <td style={{ textAlign: 'center' }}>{item.floor}</td>
                        <td style={{ textAlign: 'center' }}>{item.window}</td>
                        <td style={{ textAlign: 'center' }}>{item.windowSquare} м<sup>2</sup></td>
                        <td style={{ textAlign: 'center' }}>{item.balconWindowSquare} м<sup>2</sup></td>
                        <td style={{ textAlign: 'center' }}>{item.dirt ? <img src="/IconIllustration/check_green.svg" /> : ''}</td>
                        <td style={{ textAlign: 'center' }}>{item.time}</td>
                        <td style={{ textAlign: 'center' }}>{item.price}</td>
                        <td style={{ textAlign: 'center' }}>{item.name}</td>
                        <td style={{ textAlign: 'center' }}>{item.phone}</td>
                    </tr>

                ))}
            </table>
        </div>
    );
};
