import React, { useState } from 'react';
import { Button, Phone, Telegram, WhatsApp } from '../Button';
import { CallForm } from '../Form/CallForm';
import { Modal } from '../Modal';
import style from './SalePeople.module.css'

export const SalePeople = ({
    title,
    description,
    button2,
    button2href,
    button3,
    button3href,
    image }) => {

    const [open, setOpen] = useState(false)
    const [openCallForm, setOpenCallForm] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    return (
        <div className={style.banner}>


            {openCallForm
                ?
                <Modal setActive={setOpenCallForm} submitted={submitted} >
                    <CallForm setSubmitted={setSubmitted} />
                </Modal>
                : null
            }


            <div className={style.text}>
                <h3>{title}</h3>
                <div className={style.line} />
                <p className={style.description}>{description}</p>
                <div className={style.button}>
                    <a href="https://wa.me/79628120000" target='_blank'>
                        <Button
                            buttonStyle={"btn--black"}
                            buttonSize={"btn--m"}
                            IconRight={'whatsapp'}
                            IconColor={'var(--green)'}
                        >Whats App
                        </Button>
                    </a>
                    {button2
                        ?
                        <a href={button2href ? button2href : null}>
                            <Button
                                buttonStyle={"btn--blue"}
                                buttonSize={"btn--m"}
                                IconRight={'phone'}
                                IconColor={'var(--white)'}
                                onClick={() => setOpenCallForm(!openCallForm)}
                            >{button2}
                            </Button>
                        </a>
                        : null
                    }

                    {button3
                        ?
                        <a href={button3href ? button3href : null}>
                            <Button
                                buttonStyle={"btn--white"}
                                buttonSize={"btn--m"}
                                IconRight={'arrowR'}
                                IconColor={'var(--text-black)'}
                            >{button3}
                            </Button>
                        </a>
                        : null}
                </div>
            </div>


            {
                image
                    ?
                    <div className={style.sale}>
                        <img src={image} alt="" />
                    </div>
                    : null
            }

        </div >
    );
};




export const SalePeopleHeaderBanner = () => {
    return (
        <a href="/people-service/moyka-okon">
            <div className={style.bannerHeader}>
                <p> Помыть окна в квартире недорого в Санкт-Петербурге: скидки до 50%</p>
                <div />
            </div>
        </a>
    );
};


