import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import style from './header.module.css'
import { Button, Instagram, Phone, Telegram, Vkontakte, WhatsApp, YouTube } from '../Button';
import { useGetDoc } from '../../Hook/useGetDoc';
import { SalePeopleHeaderBanner } from '../Banners/SalePeople';
import { Modal } from '../Modal';
import { CallForm } from '../Form/CallForm';
import { useAuth } from '../../Authentication/AuthContext';



export const Header = () => {

    const [open, setOpen] = useState(false)
    const [openCallForm, setOpenCallForm] = useState(false)
    const [submitted, setSubmitted] = useState(false)


    return (
        <>
            {/* <SalePeopleHeaderBanner /> */}
            <header>

                {openCallForm
                    ?
                    <Modal setActive={setOpenCallForm} submitted={submitted} >
                        <CallForm setSubmitted={setSubmitted} />
                    </Modal>
                    : null
                }

                <div className={style.containerBrandPlace}>
                    <div className={style.brandPlace}>
                        <div className={style.logo}>
                            <Link to="/"><img src="/logo.svg" style={{ width: '122px' }} /></Link>
                            <div className={style.slogan}>
                                <div className={style.line}></div>
                                <h6>Промышленный альпинизм Cанкт-Петербург</h6>
                            </div>
                        </div>
                        <div className={style.contact}>
                            <div className={style.messenger}>
                                <a className={style.hotCallNumber} href="tel:+79628120000">
                                    <Button
                                        type="button"
                                        buttonStyle={"btn--grey"}
                                        buttonSize={"btn--s"}
                                        IconRight={'hotCall'}
                                        IconColor={'var(--ligadobra)'}
                                    >8 962 812 0000
                                    </Button>
                                </a>
                                <span className={style.orderCall}>
                                    <Button
                                        type="button"
                                        buttonStyle={"btn--blue"}
                                        buttonSize={"btn--s"}
                                        IconRight={'phone'}
                                        IconColor={'white'}
                                        onClick={() => setOpenCallForm(!openCallForm)}
                                    >Заказать звонок
                                    </Button>
                                </span>
                                <span className={style.connect}>
                                    <Button
                                        type="button"
                                        buttonStyle={"btn--green"}
                                        buttonSize={"btn--s"}
                                        IconRight={'whatsapp'}
                                        IconColor={'white'}
                                        onClick={() => setOpenCallForm(!openCallForm)}
                                    >Связаться
                                    </Button>
                                </span>
                                <span className={style.iconMessenger}><WhatsApp /></span>
                                <span className={style.iconMessenger}><Telegram /></span>
                                <a className={style.orderFormButton} href="/order-form">
                                    <Button
                                        type="button"
                                        buttonStyle={"btn--black"}
                                        buttonSize={"btn--s"}
                                    >Форма заявки
                                    </Button>
                                </a>
                                {/* <span className={style.iconMessenger}><Phone /></span> */}
                            </div>

                            {/* <div className={style.social}>
                                <Instagram />
                                <YouTube />
                                <Vkontakte />
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className={style.containerNavBar}>
                    <div className={style.navBar}>
                        <NavBar />
                        <img
                            src="/IconIllustration/BurgerIcon.svg"
                            style={{ width: '24px' }}
                            onClick={() => setOpen(!open)}
                        />
                    </div>
                </div>
                <BurgerMenu active={open} setActive={setOpen} />
            </header>
        </>
    );
};

{/* <BurgerButton /> */ }


const NavBar = () => {

    const { docs } = useGetDoc('services')
    const { user } = useAuth()

    return (
        <>
            <div className={style.menu}>

                {user
                    ?
                    <NavLink
                        className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyXPhone}` : `${style.link} ${style.linkOnlyXPhone}`}
                        to="/admin">Админ
                    </NavLink>
                    : null
                }
                <div className={style.dropdown}>


                    <NavLink
                        className={({ isActive }) => isActive ? `${style.linkActive} ${style.menuArrow}` : `${style.link} ${style.menuArrow} `}
                        to="/company-service">Уcлуги
                    </NavLink>

                    <div className={style.dropdownContainer}>
                        <div className={style.dropdownContent}>
                            <div className={style.dropdownSections}>
                                <div className={style.dropdownSection}>
                                    <h6>Обслуживание и ремонт зданий</h6>
                                    <div className={style.services}>
                                        {docs.filter(unit => unit.category === 'buildingService').map(service => <a href={`/company-service/${service.link}`}>{service.serviceName}</a>)}
                                    </div>
                                </div>
                                <div className={style.dropdownSection}>
                                    <h6>Монтаж / Демонтаж</h6>
                                    <div className={style.services}>
                                        {docs.filter(unit => unit.category === 'montage').map(service => <a href={`/company-service/${service.link}`}>{service.serviceName}</a>)}
                                    </div>
                                </div>
                                <div className={style.dropdownSection}>
                                    <h6>Услуги для жителей</h6>
                                    <div className={style.services}>
                                        {docs.filter(unit => unit.client === 'people').map(service => <a href={`/people-service/${service.link}`}>{service.serviceName}</a>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <NavLink
                    className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyXPhone}` : `${style.link} ${style.linkOnlyXPhone}`}
                    to="/price">Цены
                </NavLink>

                <NavLink
                    className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyTablet}` : `${style.link} ${style.linkOnlyTablet}`}
                    to="/career">Карьера
                </NavLink>

                <NavLink
                    className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyDesktop}` : `${style.link} ${style.linkOnlyDesktop}`}
                    to="/about">О компании
                </NavLink>

                <NavLink
                    className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyDesktop}` : `${style.link} ${style.linkOnlyDesktop}`}
                    to="/contact">Контакты
                </NavLink>

                <NavLink
                    className={({ isActive }) => isActive ? `${style.linkActive} ${style.linkOnlyDesktop}` : `${style.link} ${style.linkOnlyDesktop}`}
                    to="/faq/q-people">Вопрос-ответ
                </NavLink>
            </div>
        </>

    );
};



const BurgerMenu = ({ active, setActive }) => {

    return (
        <div className={active ? `${style.mobileNav} ${style.active}` : style.mobileNav} onClick={() => setActive(false)}>
            <div className={active ? `${style.contentNav} ${style.active}` : style.contentNav} onClick={(e) => e.stopPropagation()}>
                <a href="/company-service"><h3>Услуги</h3></a>
                <a href="/career"><h3>Карьера</h3></a>
                <a href="/search-promalp"><h3>Поиск исполнителей</h3></a>
                <a href="/projects"><h3>Наши проекты</h3></a>
                <a href="/blog"><h3>Блог</h3></a>
                <a href="/faq"><h3>Вопрос-ответ</h3></a>
                <a href="/about"><h3>О компании</h3></a>
                <a href="/liga-dobra"><h3>Лига добра</h3></a>
                <a href="/contact"><h3>Контакты</h3></a>
            </div>
            <div className={active ? `${style.overleyNav} ${style.active}` : style.overleyNav} />
        </div>
    );
};
