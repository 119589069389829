import { v4 as uuidv4 } from 'uuid'
import { UborkaSnega } from '../Price/Price'

const questionsRemain =
{
    question: 'Остались вопросы?',
    answer: 'Если вы не нашли ответ или у вас остались вопросы, свяжитесь с нами, мы все расскажем.',
    id: uuidv4(),
}

const germetizaciya = [
    {
        question: 'Как вы проводите герметизацию окон?',
        answer: 'Герметизация окон — это процесс замены или нанесения герметика на оконные стыки, чтобы сделать их водонепроницаемыми и защищенными от атмосферных воздействий. Для каждого типа шва мы подбираем соответствующий тип герметика и соблюдаем строгую технологию процесса.',
        id: uuidv4(),
    },
    {
        question: 'Из чего состоит процесс герметизации?',
        answer: 'Мы соблюдаем 4-этапный процесс герметизации окон: осмотр, очистка, подготовка поверхности, нанесение герметика.',
        id: uuidv4(),
    },
    {
        question: 'Какие виды услуг по герметизации вы оказываете?',
        answer: 'Проверка герметичности окон, обнаружение проблем утечек, промерзаний и плесени; гидроизоляция стеклянных фасадов; заделка окон высотных зданий; заделка и герметизация мансардных окон.',
        id: uuidv4(),
    },
    {
        question: 'Сколько стоит заделать швы на фасаде?',
        answer: 'Чтобы рассчитать стоимость, нам необходимо провести исследование фасада и определить оптимальный способ восстановления.',
        id: uuidv4(),
    },
    {
        question: 'Чем герметизируют швы?',
        answer: 'Все зависит от вида дефекта и его расположения на фасаде. После исследования, специалист подберет тип герметика, который необходимо применять в вашем случае.',
        id: uuidv4(),
    },
    {
        question: 'Поможет ли герметизация при промерзании стены?',
        answer: 'Частой причиной промерзания стены являются стыки на фасаде, которые нуждаются в ремонте. В большинстве случаев, дефект устраняется с помощью герметизации. ',
        id: uuidv4(),
    },
    {
        question: 'Как избавиться от плесени и грибка на стене?',
        answer: 'Плесень и грибок — это нарушения режима парообмена в стенах здания. Хорошая герметизация с соблюдением технологического процесса и правильно подобранным герметиком позволяет устранить проблемы.',
        id: uuidv4(),
    },
    {
        question: 'Как часто нужно проводить герметизацию?',
        answer: 'Современные материалы и заполнитель долговечны. Качественно проведенная герметизация, с подбором правильного материала позволит забыть вам о проблеме с фасадом.',
        id: uuidv4(),
    },
    {
        question: 'От чего разрушается фасад?',
        answer: 'Необходимость проведения герметизации может быть связана с появлением разрушений, вызванных течением времени или халатностью при строительстве.',
        id: uuidv4(),
    },
    {
        question: 'Кто должен проводить герметизацию УК или собственник?',
        answer: 'Скоро будет ответ',
        id: uuidv4(),
    },
    questionsRemain
]

export const localDataService = [
    {
        name: 'myteOkonPeople',
        client: "people",
        serviceName: 'Мытье окон',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        image: '/IconIllustration/peopleService_mitioOkon.jpg',
        faq: [
            {
                id: uuidv4(),
                question: 'Как происходит мытье окон и оплата?',
                answer: 'Точно в назначенное время, специалист приступает к работе. Вам не обязательно находится в это время дома. Убедитесь, что вы закрыли окна и сняли москитные сетки. После того, как работа будет выполнена, на ваш номер телефона придет сообщение с фотографиями ваших чистых окон. На окнах будет оставлен лист с реквизитами и удобными для вас формами оплаты.',
            },
            {
                id: uuidv4(),
                question: 'Какие моющие средства вы используете?',
                answer: 'Мы используем профессиональные моющие средства для мытья окон Unger (Германия). Средства сохраняют блеск и чистоту стекол на длительный срок благодаря специальному составу. Средства безопасны для людей и животных.',
            },
            {
                id: uuidv4(),
                question: 'Как я могу получить скидку?',
                answer: 'Мы предоставляем скидку от объема работ. Вы можете договориться с соседями, которые тоже хотят очистить окна по выгодной цене.',
            },
            {
                id: uuidv4(),
                question: 'Какие этажи вы моете?',
                answer: 'Мы моем окна и фасады с применением промышленного альпинизма на любом этаже, даже в самых труднодоступных местах. Наша команда мойщиков имеет большой опыт работ на высоте.',
            },
            {
                id: uuidv4(),
                question: 'Мне нужно вносить аванс?',
                answer: 'Нет, мы принимаем оплату любым удобным для вас способом только после проделанной работы.',
            },
            {
                id: uuidv4(),
                question: 'Как оплачивать услугу?',
                answer: 'После того, как работа будет выполнена, на ваш номер телефона придет сообщение с фотографиями ваших чистых окон. На окнах будет оставлен лист с реквизитами, выберите удобную для вас форму оплаты.',
            },
            {
                id: uuidv4(),
                question: 'Меня предупредят перед приездом мойщика?',
                answer: 'Да, мы предупредим вас перед приездом. Если что, ваше нахождение в квартире в момент мойки окон совсем необязательно. Мойщик сделает свое дело и вам поступит сообщение с изображением ваших чистых окон. Занимайтесь своими повседневными делами, а мы сделаем свою работу не отвлекая вас.',
            },
            {
                id: uuidv4(),
                question: 'Нужно ли снимать экраны москитных сеток?',
                answer: 'Обязательно удаляйте оконные сетки перед мытьем окон альпинистами. Пока мы моем снаружи ваши окна, вы можете очистить сетки от пыли и грязи и установить их после того как мы помоем ваши окна',
            },
            {
                id: uuidv4(),
                question: 'Когда лучше всего мыть окна? Моют ли окна в дождь?',
                answer: 'Мы рекомендуем мыть *наружные окна два раза в год: весной (апрель-июнь) и осенью (сентябрь-ноябрь). Так, ваши окна останутся  чистыми большую часть года. Допускаем мытье окон в мелкий дождь, это не оставляет грязи и разводов на окнах и никак не отразиться на их чистоте благодаря использованию профессиональных средств для стекол, которые оставляют невидимый защитный слой на поверхности.',
            },
            {
                id: uuidv4(),
                question: 'Возможно ли заказать мытье окон снаружи и внутри?',
                answer: 'Это индивидуальная услуга. Так как мы специализируемся на промышленном альпинизме и мытье остекления снаружи, то выполняем такую услугу совместно с проверенными партнером — клининговой компанией.',
            },
            {
                id: uuidv4(),
                question: 'Возможно ли очищение наружных окон и работы на фасаде в здании, где не предусмотрены крепления для работы промальпинистов?',
                answer: 'Да, мы работаем со зданиями, где есть проблемы доступа или сложные архитектурные решения. Мы оцениваем различные факторы, чтобы определить наиболее безопасный способ для минимизации риска для наших работников, пешеходов и имущества.',
            },
            questionsRemain
        ],
        feedback: [
            {
                quote: 'Ильшат, не могу налюбоваться чистотой своих стекол…они такими отродясь не бывали…Я ворона-верхогляд. За окна СПАСИБО!',
                author: '',
                id: 1,
            },
            {
                quote: 'Вернулась домой. Балкон и окно намыли просто обалденно. Вообще все аж отражается теперь. Прям очень чисто-чисто и прозрачно! До зеркального блеска. Молодцы!',
                author: '',
                id: 2,
            },
            {
                quote: 'Отправила деньги с чаевыми) за хорошую работу не жалко) Спасибо еще раз.',
                author: '',
                id: 6,
            },
            {
                quote: 'Помыли все хорошо. Ни разводов, ни какой-то остаточной грязи. Выполнено на совесть. Тем, кто думает заказывать помывку или нет - советую заказать. Стоимость не высокая, да и зачем тратить свое время, когда это могут сделать профессионалы.',
                author: '@Chika Veronika',
                id: 3,
            },
            {
                quote: 'Большое спасибо! Очень благодарна за качественную, добросовестную работу! Буду рада обращаться к вам в дальнейшем. Благодарю сотрудников! Так держать!',
                author: '',
                id: 4,
            },
            {
                quote: 'Cпасибо большое альпинистам за чистоту балконных стекол, осталась довольна))',
                author: 'Лариса Гудим',
                id: 5,
            },
        ]
    },
    {
        name: 'podemGruzov',
        client: "people",
        serviceName: 'Подъем грузов',
        icon: '/IconIllustration/PeopleServiceIcon.svg',

    },
    {
        name: 'germetizaciyaPeople',
        client: "people",
        serviceName: 'Герметизация и утепление',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: germetizaciya
    },
    {
        name: 'germetizaciyaCompany',
        client: "company",
        serviceName: 'Герметизация и утепление',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: germetizaciya
    },
    {
        name: 'montazhKondicionerovPeople',
        client: "people",
        serviceName: 'Монтаж кондиционеров',
        icon: '/IconIllustration/PeopleServiceIcon.svg',

    },
    {
        name: 'pomoschSpasenie',
        client: "people",
        serviceName: 'Помощь и спасение',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        situations: [
            {
                image: '/situation_cover/rebonok_odin.jpg',
                title: 'Ребенок один в квартире',
                description: "К нам обратилась женщина, которая вышла из дома и забыла ключи. Дверь захлопнулась, в квартире остался маленький ребенок. Квартира находилась на 9 этаже, балкон остался открытым. Команда Высшая лига быстро попала на кровлю и успела предотвратить несчастный случай с ребенком — оказавшись без присмотра, малыш добрался до ванной комнаты и уплетал стиральный порошок. Все обошлось, благодаря оперативности. Будьте внимательны, не оставляйте бытовую химию, лекарства и другие опасные вещества в зоне доступа детей.",
                id: 1,
            },
            {
                image: "/situation_cover/propal_sosed.jpg",
                title: "Пропал сосед",
                description: "Обеспокоенные родственники, позвонили в Высшую лигу с просьбой провести инспекцию квартиры из окон на восьмом этаже. Дело в том, что пожилой человек, уже несколько дней не отвечает на звонки, дверь соседям не открывает. Сами родственники находились за границей. Через окно мы обнаружили мужчину, лежащего на полу. Вызвали службу МЧС, связались с родственниками. Берегите своих пожилых родственников, оставляйте людей, которые присмотрят за ними в ваше отсутствие.",
                id: 2,
            },
            {
                image: "/situation_cover/pes.jpg",
                title: "Пёс, который решил остаться один",
                description: "Поздним вечером позвонили молодые люди, их собака защелкнула переключатель двери и они не могли попасть в квартиру. Все бы ничего, но это была штаб-квартира IT специалистов, где находилась дорогостоящая техника. В связи с этим, их дверь была супер новороченная и дорогая — взламывать ее жалко. Решено было разбивать окно, спустившись с крыши. В данной ситуации — наиболее экономичный способ попадания в квартиру. Оперативно произвели работу, даже собака не успела среагировать на гостя вошедшего в окно. Пора учить собак командам “открыть замок!”",
                id: 3,
            },
            {
                image: "/situation_cover/mirror.jpg",
                title: "Антикварное зеркало",
                description: "В Высшую лигу обратились любители антиквариата. Они приобрели старинное резное зеркало из массива. Это очень красивая и дорогая вещь. Проблема возникла в том, что оно не вмещалось в лифт, а подымать его на 11 этаж по узкому лестничному пролету было не безопасно, владельцы боялись повредить его. Мы организовали доставку ценного предмета прямо в окно, предварительно упаковав его в твердый картон. Смело приобретайте необычные и уникальные вещи в ваш интерьер. А мы поможем доставить их до квартиры с помощью канатов, если они имеют нестандартный размер.",
                id: 4,
            },
            {
                image: '/situation_cover/supehero.jpg',
                title: 'Супергерои существуют',
                description: "Необычный сюрприз на день рождение сына решили сделать креативные родители. Мальчик был фанатом Человека Паука и семья обратилась к нам с просьбой, чтобы альпинист в костюме супергероя спустился и подарил ему подарок. Сколько удивления, восторга и эмоций было в глазах именинника. Думаем, что этот день он запомнит на всю жизнь. Подходите с креативом и удивляйте своих близких — всё возможно исполнить.",
                id: 5,
            },
        ]
    },

    {
        name: 'myteOkonPosleStroy',
        client: "company",
        serviceName: 'Мытье окон после строительства',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: [
            {
                question: 'Что входит в эту услугу?',
                answer: 'Мойка окон после строительства включает в себя удаление наклеек, клея, штукатурки, скотча, краски и других строительных материалов, а также грязи и пыли с оконных стекол и рам.',
                id: uuidv4(),
            },
            {
                question: 'Может ли строительный мусор поцарапать стекло или раму?',
                answer: 'Неправильные методы очистки могут оставить царапины на стекле. Мы имеем большой опыт послестроя и умеем справляться с очищением окон и поверхностей без царапин. Используем специальные чистящие растворы для легкого удаления каждого вида загрязнений и сводим к минимуму использование скребка. Если же использовании скребка не избежать, мы гарантируем использование новых острых лезвий.',
                id: uuidv4(),
            },
            {
                question: 'Когда нужно мыть окна во время строительного проекта?',
                answer: 'Окна всегда следует мыть в самом конце строительного проекта , после того, как будут выполнены все малярные и отделочные работы.',
                id: uuidv4(),
            },
            {
                question: 'Можно ли очистить окна после строительства аппаратом высокого давления?',
                answer: 'Мы не рекомендуем. Мойка окон после строительства и ремонта требует тщательной ручной очистки специальными средствами. АВД являются экономически эффективным решением для регулярного и планового мытья окон, но только не для проектов после строительства.',
                id: uuidv4(),
            },
            questionsRemain

        ]
    },
    {
        name: 'pokraska',
        client: "company",
        serviceName: 'Покраска высотных зданий',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: [
            {
                question: 'Как часто следует красить фасад здания?',
                answer: 'Окрашивание здания снаружи следует проводить каждые 10 лет, чтобы сохранить внешний вид и уменьшить ущерб, причиненный природой или токсинами в воздухе.',
                id: uuidv4(),
            },
            {
                question: 'Как происходит покраска фасада здания?',
                answer: 'Процесс правильной покраски не просто в нанесении нового слоя краски. Использование качественной краски и правильной технологии имеет важное значение для долговечного и хорошего внешнего вида здания. Для достижения наилучших результатов необходимо использовать четырехэтапный процесс: осмотр, подготовка поверхности, грунтовка, нанесение краски.',
                id: uuidv4(),
            },
            {
                question: 'Когда нужно мыть окна во время строительного проекта?',
                answer: 'Окна всегда следует мыть в самом конце строительного проекта , после того, как будут выполнены все малярные и отделочные работы.',
                id: uuidv4(),
            },
            {
                question: 'Можно ли очистить окна после строительства аппаратом высокого давления?',
                answer: 'Мы не рекомендуем. Мойка окон после строительства и ремонта требует тщательной ручной очистки специальными средствами. АВД являются экономически эффективным решением для регулярного и планового мытья окон, но только не для проектов после строительства.',
                id: uuidv4(),
            },
            questionsRemain

        ]
    },
    {
        name: 'pokraska',
        client: "company",
        serviceName: 'Покраска высотных зданий',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: [
            {
                question: 'Как часто следует красить фасад здания?',
                answer: 'Окрашивание здания снаружи следует проводить каждые 10 лет, чтобы сохранить внешний вид и уменьшить ущерб, причиненный природой или токсинами в воздухе.',
                id: uuidv4(),
            },
            {
                question: 'Как происходит покраска фасада здания?',
                answer: 'Процесс правильной покраски не просто в нанесении нового слоя краски. Использование качественной краски и правильной технологии имеет важное значение для долговечного и хорошего внешнего вида здания. Для достижения наилучших результатов необходимо использовать четырехэтапный процесс: осмотр, подготовка поверхности, грунтовка, нанесение краски.',
                id: uuidv4(),
            },
            {
                question: 'Когда нужно мыть окна во время строительного проекта?',
                answer: 'Окна всегда следует мыть в самом конце строительного проекта , после того, как будут выполнены все малярные и отделочные работы.',
                id: uuidv4(),
            },
            {
                question: 'Можно ли очистить окна после строительства аппаратом высокого давления?',
                answer: 'Мы не рекомендуем. Мойка окон после строительства и ремонта требует тщательной ручной очистки специальными средствами. АВД являются экономически эффективным решением для регулярного и планового мытья окон, но только не для проектов после строительства.',
                id: uuidv4(),
            },
            questionsRemain

        ]
    },
    {
        name: 'vneshnyayaOchistkaZdaniy',
        client: "company",
        serviceName: 'Внешняя очистка зданий',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: [
            {
                question: 'Как часто необходимо очищать фасад здания?',
                answer: 'Полную мойку и очистку фасада рекомендуется проводить не реже одного раза в год.',
                id: uuidv4(),
            },
            {
                question: 'Не вредит ли мойка под давлением поверхности фасада?',
                answer: 'Мы знаем как определить уровень интенсивности для каждой конкретной внешней поверхности. Наши специалисты проверяют все материалы поверхности прежде чем приступить к работам.',
                id: uuidv4(),
            },
            {
                question: 'Когда применяют мытье фасада под давлением?',
                answer: 'Мойка фасада под давлением — это метод глубокой очистки от грязи, пыли, копоти, плесени, водорослей, старой отслаивающейся краски, удаления граффити и других загрязнений.',
                id: uuidv4(),
            },
            questionsRemain

        ]
    },
    {
        name: 'soliNaStekle',
        client: "company",
        serviceName: 'Удаление солевой коррозии на стекле',
        icon: '/IconIllustration/PeopleServiceIcon.svg',
        faq: [
            {
                question: 'Что такое высолы и пятна от жесткой воды?',
                answer: 'Отложения кальция, коррозия стекла или окисление стекла являются часто распространенным типом повреждения стекла. Это может выглядеть как серые или белые размытые пятна в форме капель воды и портить не только эстетический вид здания, но и препятствовать проникновению света в помещение.',
                id: uuidv4(),
            },
            {
                question: 'Откуда появляются высолы?',
                answer: 'Пятна являются результатом коррозии стекла из-за постоянного воздействия воды и концентрации растворенных в воде неорганических соединений. Твердые вещества, растворенные в воде, прилипают к шероховатой поверхности стекла и вступают в реакцию. Результатом этих химических реакций и являются нерастворимые соединения, которые можно удалить механически или химически.',
                id: uuidv4(),
            },
            {
                question: 'Как предотвратить появление высолов?',
                answer: 'Главный виновник высолов — вода. Необходимо устранение источника воды, если это возможно.',
                id: uuidv4(),
            },
            questionsRemain

        ]
    },
    {
        name: 'uborkaSnega',
        client: "company",
        price: <UborkaSnega/>
    },

]