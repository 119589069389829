import React, { useEffect, useRef, useState } from 'react';
import style from './AddProject.module.css'

import { Input } from '../../components/Form/unitForm/input';
import { InputFile, InputFiles } from '../../components/Form/unitForm/inputFile';
import { Checkbox} from '../../components/Form/unitForm/checkAndRadio';

import { ServiceSectionContext } from '../../Context/ServicesSectionContext'
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { db, storage } from '../../firebase-config';
import { addDoc, collection } from 'firebase/firestore';

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import translit from '../../components/Translit/Translit';
import { Header } from '../../components/HeaderFooter/header';


const input = {
    title:
    {
        id: Date.now(),
        name: 'title',
        errorMessage: 'Напишите название услуги',
        label: 'Название услуги',
        required: true,
    },
    link:
    {
        id: Date.now(),
        name: 'link',
        label: 'Ссылка',
        disabled: true
    },
    cover:
    {
        id: Date.now(),
        name: 'cover',
        errorMessage: 'Загрузите обложку',
        label: 'Обложка',
        required: true,
    },
    description:
    {
        id: Date.now(),
        name: 'description',
        errorMessage: 'Кратко опишите услугу',
        label: 'Описание',
        required: true,
    },

    text:
    {
        id: Date.now(),
        name: 'text',
        errorMessage: 'Подробно опишите услугу',
        label: 'Описание услуги',
        required: true,
    },
    images:
    {
        id: Date.now(),
        name: 'images',
        type: 'file',
        label: 'Фогографии услуги',
    },
    cover:
    {
        id: Date.now(),
        name: 'cover',
        type: 'file',
        label: 'Обложка',
    },
    client:
    {
        id: Date.now(),
        name: 'client',
        label: 'Название компании',
        required: true,

    },
}


export const AddProject = () => {

    const { title, description, cover, typeService, text, images, link, client } = input

    const { typeServices } = ServiceSectionContext()

    const [serviceCover, setServiceCover] = useState(null)
    const [serviceCoverURL, setServiceCoverURL] = useState([])
    const [serviceImages, setServiceImages] = useState([])
    const [serviceImagesURL, setServiceImagesURL] = useState([])

    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [philanthropy, setPhilanthropy] = useState(false)

    const editor = useRef(null);
    const focusEditor = () => { editor.current.focus() }
    const [content, setContent] = useState('')

    const [values, setValues] = useState({
        cover: null,
        title: '',
        client: '',
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const linkPage = translit(values.title)

    const uploadCover = (callback) => {
        if (serviceCover == null) return;
        const storageRef = ref(storage, `projects/${Date.now()} + ${serviceCover.name}`) // ссылка на storage и папка
        const uploadTask = uploadBytesResumable(storageRef, serviceCover)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    callback(downloadURL)
                });
            }
        )
    }

    const uploadImages = () => {
        serviceImages.map(file => {
            const storageRef = ref(storage, `projects/${Date.now()} + ${file.name}`) // ссылка на storage и папка
            const uploadTask = uploadBytesResumable(storageRef, file)
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress)
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;

                    }
                },
                (error) => { console.log(error) },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setServiceImagesURL((prevState) => [...prevState, downloadURL]))

                }

            )
        })
    }

    // ADD FIRESTORE


    const colRef = collection(db, 'projects')
    const newService = (url) => {
        setLoading(true)
        addDoc(colRef,
            { ...values, philanthropy, images: serviceImagesURL, cover: url, link: linkPage, content }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (serviceImagesURL.length === serviceImages.length) {
            uploadCover((url) => {
                newService(url)
            })
        }
    }, [serviceImagesURL]);

    const handleSubmit = (e) => {
        e.preventDefault()
        uploadImages()
        setSubmitted(true)
    }

    //EDITOR

    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                // [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button

            ]
        }
    }
    const formats = [
        // 'background',
        'bold',
        // 'color',
        // 'font',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'block',
        'image',
        'video',
    ]

    return (
        <>
            <Header />
            <main>
                <form onSubmit={handleSubmit}>
                    <div className={style.form}>

                        <Input
                            {...client}
                            value={values[client?.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...title}
                            value={values[title?.name]}
                            onChange={onChange}
                        />
                        <Checkbox
                            type='checkbox'
                            label='Благотворительный проект'
                            checked={philanthropy}
                            onChange={(e) => setPhilanthropy(e.target.checked)}
                        />
                        <InputFile
                            {...cover}
                            value={values[title?.name]}
                            selectedFile={serviceCover}
                            setSelectedFile={setServiceCover}
                        />
                        <InputFiles
                            {...images}
                            value={values[title?.name]}
                            selectedFiles={serviceImages}
                            setSelectedFiles={setServiceImages}
                        />
                        <div className={style.contentEditor}>
                            < ReactQuill
                                ref={editor}
                                value={content}
                                onChange={e => setContent(e)}
                                theme='snow'
                                modules={modules}
                                formats={formats}
                            />
                        </div>
                        <button disabled={loading} >Отправить</button>
                    </div>
                </form>

            </main>

        </>
    );
};