import React from 'react';
import { useState, useEffect } from 'react'
import { addDoc, collection } from "firebase/firestore";
import { db } from '../../firebase-config';
import style from './PeopleUniversal.module.css'
import { Modal } from '../Modal';
import { Button } from '../Button';

import { Input } from './unitForm/input';
import { Select } from './unitForm/select';
import { CheckboxPersonalData } from './unitForm/checkAndRadio';
import { TextArea } from './unitForm/textArea';

import { formData } from './formData'
import { region } from './formData'
import { services } from './formData'


export const PeopleUniversalForm = ({ service }) => {

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const { address, name, phone, personalData, comment } = formData

    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [values, setValues] = useState({
        region: '',
        services: `${service?.serviceName}`,
        address: '',
        name: '',
        phone: '',
        comment: '',
        date
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const peopleCleaningData = collection(db, 'PeopleServices') // передаем коллекцию firebase
    const newOrder = () => { //ссылка на коллекцию при заполнении формы
        setLoading(true)
        addDoc(peopleCleaningData,
            { ...values }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        newOrder()
        setSubmitted(true);
    }

    return (
        <>
            {submitted ? <Modal loading={loading} setActive={setSubmitted} submitted={submitted} /> : null}

            <form onSubmit={handleSubmit}>
                <div className={style.form} >
                    <div className={style.inputContainer}>
                        <h3>{service?.title}</h3>
                        <div className={style.inputs}>
                            <Select
                                label='Выберите район проживания'
                                options={region}
                                onChange={(option) => (
                                    setValues({ ...values, region: option })
                                )}
                            />
                            <Input
                                {...address}
                                value={values[address.name]}
                                onChange={onChange}
                            />
                            <Input
                                {...name}
                                value={values[name.name]}
                                onChange={onChange}
                            />
                            <Input
                                {...phone}
                                value={values[phone.name]}
                                onChange={onChange}
                            />
                            <TextArea
                                {...comment}
                                value={values[comment.name]}
                                onChange={onChange}
                                placeholder='Напишите комментарий или задайте вопрос'
                                />

                            <CheckboxPersonalData />

                        </div>
                        <div className={style.button}>
                            <Button
                                type="submit"
                                buttonStyle={"btn--blue"}
                                buttonSize={"btn--m"}
                                IconRight={'arrowR'}
                                IconColor = {'white'}
                            >Отправить заявку
                            </Button>
                        </div>

                    </div>
                </div>
            </form>
        </>
    );
};