import React from 'react';
import style from './Card.module.css'
import { BlogSectionContext } from '../../Context/BlogSectionContext';
import { Link, NavLink } from 'react-router-dom';
import { Button } from '../Button';




export const CardClients = ({ client }) => {

    return (
        <div className={style.containerClient} >
            <a href={client.button}>
                <div className={style.clientImage}> <img src={client?.image} alt="" /></div>
                <div className={style.clientTitle}><h3>{client?.title}</h3></div>
            </a>
            {client.link
                ?
                <div className={style.clientServices}>
                    {client.services.map(url =>
                        <a href={url.url} key={url.title}><p className={style.clientService}>{url.title}</p></a>
                    )}
                    <div className={style.clientButton}>
                        <a href={client.button} >
                            <Button
                                type="button"
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--s"}
                                IconRight={'arrowR'}
                                IconColor={'var(--green)'}
                            >Все услуги
                            </Button>
                        </a>
                    </div>
                </div>
                :
                <div className={style.clientServices}>
                    <p className={style.help}>Оказываем помощь, если люди попадают в нестандартные ситуации. Звоните, если требуется помощь на высоте.</p>
                    <div className={style.clientButton}>
                        <a href='tel:+79628120000' >
                            <Button
                                type="button"
                                buttonStyle={"btn--red"}
                                buttonSize={"btn--s"}
                                IconRight={'phone'}
                                IconColor={'white'}
                            >Экстренный звонок
                            </Button>
                        </a>
                    </div>
                </div>
            }

        </div>
    );
};


export const CardProject = ({ url, project }) => {

    return (
        <a href={`/${url}/${project.link}`}>

            <div className={style.projectClient}>
                <div className={style.coverProject}> <img src={project?.cover} alt="" /></div>
                <div className={style.textProject}>
                    <p>{project.client}</p>
                    {/* <div className={style.descriptionProject}>
                        <p>Устранение наледи и сосулек на кровле. Профилактика плесени и ржавчины</p>
                    </div> */}
                </div>
            </div>

        </a>
    );
};



export const CardBlog = ({ info, sectionLink }) => {

    const { blogSection } = BlogSectionContext()
    const section = blogSection.find(unit => unit.id === info.section)


    return (

        <a href={`/${sectionLink}/${info.link}`}>

            <div className={style.blog}>
                <div className={style.img}>
                    <img src={info.cover} />
                </div>
                <div className={style.textBlog}>
                    {section?.name ? <h6 className={style.section}>{section?.name}</h6> : null}
                    <h3 className={style.titleBlog}>{info.title}</h3>
                </div>
            </div>

        </a>
    );
};





export const CardService = ({ url, service }) => {

    return (
        <a href={`/${url}/${service.link}`}>

            <div className={style.service}>
                <div className={style.coverService} style={{ backgroundImage: `url(${service.cover})` }} />
                <div className={style.textCardService}>
                    <h3>{service?.serviceName}</h3>
                    <div className={style.description}>
                        <p>{service?.description}</p>
                    </div>
                </div>
            </div>

        </a>
    );
};






export const CardServiceSmall = ({ url, service }) => {
    return (
        <Link className={style.cardService} to={`/${url}/${service.link}`}>
            <div className={style.cardServiceTitle}>
                <p>{service.title}</p>
                <div />
            </div>
            <div className={style.cardServiceImage}>
                <img src={service.cover} alt="" />
            </div>

        </Link>
    );
};


// карточка внутри услуги, отображает другие услуги
export const CardServiceOther = ({ url, service }) => {
    return (
        <a className={style.cardOther} href={`/${url}/${service?.link}`}>
            <div className={style.cardOtherImg}>
                <img src={service.cover} />
            </div>
            <p>{service.serviceName}</p>
        </a>
    );
};








export const CardIconsServices = ({ icon, service }) => {
    return (
        <NavLink
            className={({ isActive }) => isActive ? `${style.iconCardsActive}` : `${style.iconCards}`}
            to={`/people-service/${service.link}`}>
            <div className={style.iconCard}>
                <div className={style.icon}>
                    <img src={icon} />
                </div>
                <p className={style.titleIcon}>{service.serviceName}</p>
            </div>
        </NavLink>

    );
};







// export const CardSectionService = ({ service }) => {
//     return (
//         <a href={`/${service.link}`}>

//             <div className={style.typeService}>

//                 <div className={style.typeButton}>

//                     <div className={style.typeButtonTitle}>
//                         <h3>{service.title}</h3>
//                     </div>

//                     <div className={style.typeButtonArrow}>
//                         <img src="/IconIllustration/arrow_typeService.svg" alt="" />
//                     </div>

//                 </div>
//                 <div className={style.typeCover} style={{ backgroundImage: `url(${service.cover})` }} />

//             </div>

//         </a>
//     );
// };










