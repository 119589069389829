import React from 'react';
import { useState, useEffect } from 'react'
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from '../../firebase-config';
import style from './Company.module.css'
import { Button } from '../Button';
import { Modal } from '../Modal';

import { Input } from './unitForm/input';
import { Checkbox, CheckboxPersonalData } from './unitForm/checkAndRadio';
import { TextArea } from './unitForm/textArea';
import { InputFile } from './unitForm/inputFile';

import { formData } from './formData'
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';



export const CompanyForm = ({ formName }) => {

    const { company, name, phone, email, comment, deadline } = formData

    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null)
    const [fileURL, setFileURL] = useState([])

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const [values, setValues] = useState({
        company: '',
        name: '',
        phone: '',
        email: '',
        comment: '',
        deadline: '',
        date
    })

    const uploadFile = (selectedFile) => {
        // if (selectedFile == null) return;
        const storageRef = ref(storage, `/public/PromalpManDocs/${Date.now()} + ${selectedFile.name}`) // ссылка на storage и папка
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFileURL(downloadURL)
                    setLoading(false)
                });
            }
        )
    }


    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }


    const peopleCleaningData = collection(db, 'Company') // передаем коллекцию firebase
    const newOrder = (url) => { //ссылка на коллекцию при заполнении формы
        addDoc(peopleCleaningData,
            { ...values, file: fileURL }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        newOrder()
        setSubmitted(true);
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }

    return (
        <>
            {submitted ? <Modal loading={loading} setActive={setSubmitted} submitted={submitted} /> : null}

            <form className={style.form} onSubmit={handleSubmit}>

                <div className={style.inputContainer}>
                    {formName
                        ? <h3>{formName}</h3>
                        : <h3>Отправить заявку</h3>}

                    <div className={style.inputs}>

                        <Input
                            {...company}
                            value={values[company.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...name}
                            value={values[name.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...phone}
                            value={values[phone.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...email}
                            value={values[email.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...deadline}
                            value={values[deadline.name]}
                            onChange={onChange}
                        />
                        <TextArea
                            {...comment}
                            value={values[comment.name]}
                            onChange={onChange}
                            placeholder='Опишите проект или загрузите техническое задание'
                        />

                    </div>
                    <InputFile
                        label='Загрузить ТЗ'
                        uploadFile={uploadFile}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                    />
                </div>
                <div className={style.button}>
                    <CheckboxPersonalData />
                    <Button
                        type="submit"
                        buttonStyle={"btn--blue"}
                        buttonSize={"btn--m"}
                        IconRight={'arrowR'}
                        IconColor = {'white'}
                    >Отправить
                    </Button>
                </div>
            </form>
        </>
    );
};