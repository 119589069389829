import React from 'react';
import style from './GeneralMessage.module.css'

export const GeneralMessage = ({ children, img }) => {
    return (
        <div className={style.container}>
            <div className={style.text}>{children}</div>
            <div className={style.image}>
                {img
                    ? <div className={style.img}>
                        <img src={img} />
                    </div>
                    : null}
            </div>
        </div>
    );
};
