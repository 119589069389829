import { Button } from '../../components/Button/button';
import React from 'react';
import style from './footer.module.css';

export const Footer = () => {

    const btnSocial = [
        {
            social: 'Instagram',
            icon: '/IconIllustration/iconInstagram.png',
            href: 'https://www.instagram.com/vishayliga13',
        },
        {
            social: 'YouTube',
            icon: '/IconIllustration/iconYouTube.png',
            href: 'https://www.youtube.com/channel/UC2SjLn3udptK83N8AV9561A',
        },
        {
            social: 'Вконтакте',
            icon: '/IconIllustration/iconVk.png',
            href: 'https://vk.com/vishayliga13',
        },
        {
            social: 'Telegram',
            icon: '/IconIllustration/iconTelegram.png',
            href: 'https://t.me/vishayliga13_promalp',

        },

    ]

    return (
        <footer>
            <div className={style.footerContainer}>
                <div className={style.contentFooter}>
                    <div className={style.social}>
                        <p> Подписывайтесь на нас в соцсетях:</p>
                        <div className={style.iconsSocial}>
                            {btnSocial.map(btn =>
                                <a
                                    className={style.socialButton}
                                    href={btn.href}
                                    target='_blank'
                                    key={btn.social}
                                >
                                    <div className={style.socialIcon}>
                                        <img src={btn.icon} />
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>

                    <div className={style.services}>
                        <a href="/company-service">
                            <Button
                                type="button"
                                buttonStyle={"btn--white"}
                                buttonSize={"btn--s"}
                                IconRight={'arrowR'}
                                IconColor={'var(--green)'}
                            >Услуги для компаний
                            </Button>
                        </a>
                        
                        <a href="/people-service/moyka-okon">
                            <Button
                                type="button"
                                buttonStyle={"btn--white"}
                                buttonSize={"btn--s"}
                                IconRight={'arrowR'}
                                IconColor={'var(--green)'}
                            >Услуги для жителей
                            </Button>
                        </a>

                    </div>

                </div>

                <div className={style.footer}>
                    <div className={style.logo}>
                        <img src="/logoWhite.png" style={{ width: '122px' }} />
                    </div>
                    <p className={style.copy}>© ООО «Высшая лига», 2022</p>
                    <div className={style.link}>
                        <a href="/personal-data">Политика конфиденциальности</a>
                    </div>
                </div>

            </div>
        </footer>
    );
};
