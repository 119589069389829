import { v4 as uuidv4 } from 'uuid'


export const newsInputs = {
    title:
    {
        id: uuidv4(),
        name: 'title',
        label: 'Заголовок',
        errorMessage: 'Нужен заголовок',
        required: true,
        placeholder: ' ',

    },

    altTextCover:
    {
        id: uuidv4(),
        name: 'altTextCover',
        label: 'Альтернативный текст к обложке',
        errorMessage: 'Нужен для seo',
        required: true,
        placeholder: ' ',
    },
    link:
    {
        id: uuidv4(),
        name: 'link',
        label: 'Ссылка',
        disabled: true,
        placeholder: ' ',

    },
    cover:
    {
        id: uuidv4(),
        name: 'cover',
        errorMessage: 'Загрузите обложку',
        label: 'Обложка',
        required: true,
    },
    description:
    {
        id: uuidv4(),
        name: 'description',
        errorMessage: 'Кратко опишите услугу',
        label: 'Описание',
        required: true,
    },
    sponsoredLink:
    {
        id: uuidv4(),
        name: 'sponsoredLink',
        label: 'Ссылка на магазин',
        // errorMessage: 'Кратко опишите услугу',
        // required: true,
    },

    sponsor:
    {
        id: uuidv4(),
        name: 'sponsor',
        label: 'Название магазина',
        // errorMessage: 'Кратко опишите услугу',
        // required: true,
    },

    text:
    {
        id: uuidv4(),
        name: 'text',
        errorMessage: 'Подробно опишите услугу',
        label: 'Описание услуги',
        required: true,
    },
    cover:
    {
        id: uuidv4(),
        name: 'cover',
        type: 'file',
        label: 'Обложка',
    },
}