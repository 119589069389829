import React, { useEffect } from 'react';
import style from './GetOrderForms.module.css'
import { useState } from 'react';

import { GetAnketaCollection } from './GetAnketaCollection'
import { GetCompanyCollection } from './GetCompanyCollection'
import { GetPeopleCleaningCollection } from './GetPeopleCleaningCollection'
import { GetPeopleServicesCollection } from './GetPeopleServicesCollection'
import { GetSearchPromalpCollection } from './GetSearchPromalpCollection'
import { GetCallCollection } from './GetCallCollection';
import { Header } from '../../components/HeaderFooter/header';

const sideBarAdmin = [
    {
        id: 'PeopleCleaning',
        name: 'От жителей (клининг)',
    },
    {
        id: 'ServicePeople',
        name: 'От жителей',
    },
    {
        id: 'Anketa',
        name: "Анкеты промальпов",
    },
    {
        id: 'Company',
        name: "От компаний",
    },
    {
        id: 'SearchPromalp',
        name: "Рекрутинг",
    },
    {
        id: 'Call',
        name: 'Перезвонить',
    },
];

const orderForms = [
    {
        id: 'PeopleCleaning',
        form: <GetPeopleCleaningCollection />,
    },
    {
        id: 'ServicePeople',
        form: <GetPeopleServicesCollection />,
    },
    {
        id: 'Anketa',
        form: <GetAnketaCollection />,
    },
    {
        id: 'Company',
        form: <GetCompanyCollection />,
    },
    {
        id: 'SearchPromalp',
        form: <GetSearchPromalpCollection />,
    },
    {
        id: 'Call',
        form: <GetCallCollection />,
    },
]

export const GetOrderForms = () => {

    const [selectOrder, setSelectOrder] = useState('PeopleCleaning')
    const [filteredOrder, setFilteredOrder] = useState([])

    const handleChangeOrder = (e) => {
        const { id } = e.target
        setSelectOrder(id)
    }

    useEffect(() => {
        setFilteredOrder(orderForms.find(unit => unit.id === selectOrder))
    }, [selectOrder]);


    return (
        <>
            <Header />
            <main className={style.content}>

                <div className={style.filter}>
                    {sideBarAdmin.map((unit) => {
                        return <p
                            key={unit.id}
                            id={unit.id}
                            onClick={handleChangeOrder}
                            className={style.sideBarLinkLabel}
                        >
                            {unit.name}
                        </p>
                    }
                    )}
                </div>

                {filteredOrder?.form}

            </main>


        </>
    );
};