import style from './FAQ.module.css';
import React, { useState } from 'react';
import { useGetDoc } from '../Hook/useGetDoc';
import { localDataService } from '../Data/LocalDataService';




export const FAQCompany = () => {

    const { docs } = useGetDoc('services')
    const servicesFirebase = docs.filter(service => service.client === 'company')
    const servicesLocal = localDataService.filter(company => company.client === 'company')
    const [active, serActive] = useState('')


    const [id, setID] = useState('')
    const getID = (e) => {
        setID(e.target.name)
    }

    const serviceFAQs = servicesLocal.find(data => data.name === id)

    return (
        <div className={style.blockFAQ}>

            <div className={style.services}>
                <div className={style.servicesDesktop}>
                    <Services
                        servicesFirebase={servicesFirebase}
                        onClick={getID}
                        id={id} />
                </div>

                {/* мобильная версия */}
                <div className={style.servicesMobile}>
                    <ServicesMobile servicesFirebase={servicesFirebase} servicesLocal={servicesLocal} />
                </div>
            </div>

            <div className={style.serviceFAQs}>
                {serviceFAQs?.faq?.map(unit => <FAQ unit={unit} />)}
            </div>

        </div>

    );
};




export const FAQPeople = () => {

    const [id, setID] = useState('germetizaciyaPeople')
    const getID = (e) => {
        setID(e.target.name)
    }
    const { docs } = useGetDoc('services')

    const servicesFirebase = docs.filter(service => service.client === 'people')
    const servicesLocal = localDataService.filter(people => people.client === 'people')
    const serviceFAQs = servicesLocal.find(data => data.name === id)

    return (
        <>
            <div className={style.blockFAQ}>

                <div className={style.services}>
                    <div className={style.servicesDesktop}>
                        <Services
                            servicesFirebase={servicesFirebase}
                            onClick={getID}
                            id={id} />
                    </div>

                    {/* мобильная версия */}
                    <div className={style.servicesMobile}>
                        <ServicesMobile servicesFirebase={servicesFirebase} servicesLocal={servicesLocal} />
                    </div>
                </div>

                <div className={style.serviceFAQs}>
                    {serviceFAQs?.faq?.map(unit => <FAQ unit={unit} />)}
                </div>

            </div>
        </>
    );
};



// Кнопки с услугами
export const Services = ({ servicesFirebase, onClick, id }) => {

    return (
        <>
            {servicesFirebase?.map(service => (
                <>
                    <button
                        className={`${service.name}` === id ? style.buttonActive : style.button}
                        name={service.name}
                        onClick={onClick}
                    >
                        {service.serviceName}
                    </button>
                </>
            ))}
        </>

    );
};




// Вопросы и ответы
export const FAQ = ({ unit }) => {

    const [active, setActive] = useState(false)

    return (

        < div className={style.faqDropdown} onClick={() => setActive(!active)}>
            <div className={style.question}>
                <p>{unit?.question}</p>
                <img className={active ? `${style.arrow} ${style.active}` : style.arrow} src="/IconIllustration/OpenAnswer.svg" />
            </div>

            {
                active &&
                <div className={active ? `${style.answer} ${style.active}` : style.answer}>
                    <p>{unit?.answer}</p>
                </div>
            }

        </div >
    );
};


// Кнопки с услугами для мобильной версии с раскрывающимися списками
export const ServicesMobile = ({ servicesFirebase, servicesLocal }) => {

    const [name, setName] = useState('')

    return (
        <>
            {servicesFirebase?.map(service => (
                <>
                    <button
                        className={`${service.name}` === name ? style.buttonActive : style.buttonMobile}
                        name={service?.name}
                        onClick={(e) => setName(e.target.name)}
                    >
                        {service?.serviceName}
                    </button>

                    {name.includes(service?.name)
                        ?
                        <div className={style.mobileFAQ} >
                            {servicesLocal?.find(unit => unit.name === service.name)
                                ? servicesLocal?.find(unit => unit.name === service.name).faq?.map(unit => <FAQ unit={unit} />)
                                : null
                            }
                        </div>
                        : null
                    }
                </>
            ))
            }
        </>

    );
};
