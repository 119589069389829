
import { AnketaPromalpForm } from './AnketaPromalp';
import { SearchPromAlplForm } from './SearchPromalp';
import { CompanylForm } from './Company';
import { v4 as uuidv4 } from 'uuid'

export const region = [
    'Адмиралтейский район',
    'Василеостровский район',
    'Волосовский район',
    'Волховский район',
    'Всеволожский район',
    'Выборгский район ',
    'Выборгский район',
    'Гатчинский район',
    'Калининский район',
    'Кингисеппский район',
    'Киришский район',
    'Кировский район',
    'Колпинский район',
    'Красногвардейский район',
    'Красносельский район',
    'Кронштадтский район',
    'Курортный район',
    'Ломоносовский район',
    'Лужский район',
    'Московский район',
    'Невский район',
    'Петроградский район',
    'Петродворцовый район',
    'Приморский район',
    'Приозерский район',
    'Пушкинский район',
    'Тихвинский район',
    'Тосненский район ',
    'Фрунзенский район',
    'Центральный район',
]
export const services = [

    'Мытье окон',
    'Подъем грузов',
    'Герметизация и утепление стен',
    'Монтаж кондиционеров',
    'Служба помощи и спасения'
]

export const time = [

    'От года',
    'До 3 лет',
    'Никогда',
    'не знаю',
]


export const formData = {

    residentArea:
    {
        id: uuidv4(),
        name: 'residentArea',
        label: 'Название ЖК',
        placeholder: ' ',
        // required: false,
    },
    address:
    {
        id: uuidv4(),
        name: 'address',
        errorMessage: 'Введите адрес',
        label: 'Адрес',
        required: true,
        placeholder: ' ',

    },

    frontDoor:
    {
        id: uuidv4(),
        name: 'frontDoor',
        placeholder: ' ',
        errorMessage: 'Введите номер',
        label: 'Парадная',
        required: true,
    },

    floor:
    {
        id: uuidv4(),
        name: 'floor',
        placeholder: ' ',
        errorMessage: 'Укажите ваш этаж',
        label: 'Этаж',
        required: true,
        inputmode: 'numeric',
    },

    window:
    {
        id: uuidv4(),
        name: 'window',
        placeholder: ' ',
        errorMessage: 'Введите количество',
        label: 'Кол-во окон',
        required: true,
        inputmode: 'numeric',
    },

    windowSquare:
    {
        id: uuidv4(),
        name: 'windowSquare',
        placeholder: ' ',
        errorMessage: 'Введите площадь одного окна',
        label: 'Площадь окна',
        // pattern: '\-?\d+(\.\d{0,})?',
        required: true,
    },

    balconWindowSquare:
    {
        id: uuidv4(),
        name: 'balconWindowSquare',
        placeholder: ' ',
        errorMessage: 'Введите площадь остекления балкона',
        label: 'Площадь остекления балкона',
        required: true,
    },

    name:
    {
        id: uuidv4(),
        name: 'name',
        placeholder: ' ',
        errorMessage: 'Введите ваше имя',
        label: 'Ваше имя',
        required: true,
    },

    phone:
    {
        id: uuidv4(),
        name: 'phone',
        placeholder: ' ',
        errorMessage: 'Введите ваш номер телефона',
        label: 'Телефон',
        required: true,
        inputmode: 'numeric',
    },

    comment:
    {
        id: uuidv4(),
        name: 'comment',
        label: 'Комментарий',
        placeholder: ' ',
    },

    company:
    {
        id: uuidv4(),
        name: 'company',
        label: 'Название компании',
        placeholder: ' ',
        required: true,
        errorMessage: 'Введите название компании',
    },

    email:
    {
        id: uuidv4(),
        name: 'email',
        // type: 'email',
        label: 'Email',
        errorMessage: 'Введите корректный email',
        required: true,
        placeholder: ' ',

    },

    password:
    {
        id: uuidv4(),
        name: 'password',
        type: 'password',
        label: 'Пароль',
        errorMessage: 'Введите пароль',
        required: true,
        autoComplete: "on",
        placeholder: ' ',

    },

    deadline:
    {
        id: uuidv4(),
        name: 'deadline',
        label: 'Сроки проекта',
        placeholder: ' ',

    },

    skills:
    {
        id: uuidv4(),
        name: 'skills',
        label: 'Профессиональные навыки',
        placeholder: ' ',

    },

    city:
    {
        id: uuidv4(),
        name: 'city',
        errorMessage: 'Укажите ваш город',
        label: 'Из какого вы города',
        required: true,
        placeholder: ' ',

    },

    privyaz:
    {
        id: uuidv4(),
        name: 'privyaz',
        label: 'Ваша привязь (название)',
        placeholder: ' ',

    },

    spuskSystem:
    {
        id: uuidv4(),
        name: 'spuskSystem',
        label: 'Система спуска (название)',
        placeholder: ' ',
    },

    strahSystem:
    {
        id: uuidv4(),
        name: 'strahSystem',
        label: 'Страховочная система (название)',
        placeholder: ' ',
    },

    udostoverenie:
    {
        id: uuidv4(),
        name: 'udostoverenie',
        label: 'Удостоверение промальпа (группа)',
        placeholder: ' ',
    },

    fileUdo:
    {
        id: uuidv4(),
        name: 'fileUdo',
        type: 'file',
        label: 'Загрузите удостоверение',
        // required: true,
        errorMessage: 'Загрузите файл',


    },

    checkDirt:
    {
        name: 'checkDirt',
        type: 'checkbox',
        label: 'Есть послестроительные загрязнения',
        // placeholder: ' ',


    },

    komandirovka:
    {
        id: uuidv4(),
        name: 'komandirovka',
        type: 'checkbox',
        label: 'Готов к командировкам',
        placeholder: ' ',
    },
    


}