
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getStorage } from "@firebase/storage";






// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDam6RUWO6L7TMWeU-ETaSD_V13gCSe2F0",
  authDomain: "high-league.firebaseapp.com",
  projectId: "high-league",
  storageBucket: "high-league.appspot.com",
  messagingSenderId: "566688281202",
  appId: "1:566688281202:web:488b2503a1f29aad9d48eb",
  measurementId: "G-ZV0YD5MGMV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const db = getFirestore (app)
export const storage = getStorage (app)