import React from 'react';
import style from './TitleBlock.module.css'

export const TitleBlock = ({ title, description }) => {
    return (
        <div className={style.titleBlock}>
            <h2>{title}</h2>
            <p className={style.lider}>{description}</p>
        </div>
    );
};



