import React from 'react';
import { useParams } from 'react-router-dom';
import style from './Singlepage.module.css'
import { SliderImage } from '../components/SliderImage/sliderImage';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { FormBlock } from '../components/Form/formBlock'
import { CompanyForm } from '../components/Form/Company'
import { useGetDoc } from '../Hook/useGetDoc';
import { BlogSectionContext } from '../Context/BlogSectionContext';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { Content } from '../components/Layout/Content/Content';
import { Button } from '../components/Button/button';


export const BlogSinglepage = () => {

    const companyForm = <CompanyForm />

    const { docs } = useGetDoc('blog')
    const { blogSection } = BlogSectionContext()

    const { link } = useParams()
    const post = docs.find((service) => service?.link === link)
    const section = blogSection.find(unit => unit.id === post.section)

    return (
        <>
            <Header />

            <main>

                <Breadcrumbs levelTwo={'Блог'} levelTwoURL='blog' levelThree={post?.title} />

                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.grid}>
                        <div className={style.title}>
                            <div className={style.titleBlog}>
                                <h6>{section?.name}</h6>
                                <h2>{post?.title}</h2>
                            </div>
                        </div>

                        <div className={style.content}>
                            <div className={style.blogCover}>
                                <img src={post?.cover} alt={post?.altTextCover} />
                            </div>

                            <div className={style.textStyle}>
                                <div dangerouslySetInnerHTML={{ __html: post?.content }}></div>
                            </div>

                            {post?.sponsoredLink
                                ?
                                <a href={post?.sponsoredLink} target='_blank'>
                                    <Button
                                        type="button"
                                        buttonStyle={"btn--black"}
                                        buttonSize={"btn--l"}
                                        IconRight={'basket'}
                                        IconColor={'var(--green)'}
                                    >Перейти на сайт партнера
                                    </Button>
                                </a>
                                : null
                            }
                        </div>

                        <div className={style.rightBlock}>
                            <div className={style.otherService}>

                                {post?.sponsoredLink
                                    ?
                                    <a href={post?.sponsoredLink} target='_blank'>
                                        <Button
                                            type="button"
                                            buttonStyle={"btn--black"}
                                            buttonSize={"btn--l"}
                                            IconRight={'basket'}
                                            IconColor={'var(--green)'}
                                        >Перейти на сайт партнера
                                        </Button>
                                    </a>
                                    : null
                                }
                            </div>
                        </div>

                    </div>
                </Content>



            </main>

            <Footer />
        </>



    );
};

{/* <div className={style.sponsored}>
                                <a href={post?.sponsoredLink} target="_blank" className={style.sponsoredLink}>
                                    <h4>{post?.sponsor}</h4>
                                    <div className={style.logo}>
                                        <img src="/IconIllustration/ArrowExLink.png" style={{ width: '30px' }} />
                                    </div>
                                </a>
                            </div> */}