import React, { useState } from 'react';
import style from './CallForm.module.css'
import { formData } from '../../components/Form/formData'
import { Input } from '../../components/Form/unitForm/input';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Button, Phone, Telegram, WhatsApp } from '../Button';



export const CallForm = ({ setSubmitted, buttonsHidden }) => {

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();


    const [loading, setLoading] = useState(false)
    const { name, phone, } = formData
    const [values, setValues] = useState({
        name: '',
        phone: '',
        date,
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const newOrder = () => {
        setLoading(true)
        addDoc(collection(db, 'CallOrder'),
            { ...values }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        newOrder()
        setSubmitted(true);
    }


    return (
        <>
            <form className={style.form} onSubmit={handleSubmit}>
                <img className={style.imageForm} src="/IconIllustration/Woman.png" alt="" />
                <div className={style.form}>
                    <Input
                        {...name}
                        value={values[name.name]}
                        onChange={onChange}
                    />
                    <Input
                        {...phone}
                        value={values[phone.name]}
                        onChange={onChange}
                    />
                    <div className={style.formButton}>
                        <Button
                            type="submit"
                            buttonStyle={"btn--blue"}
                            buttonSize={"btn--m"}
                            IconRight={'arrowR'}
                            IconColor={'white'}
                        >Заказать звонок
                        </Button>
                    </div>
                </div>
            </form>
            {buttonsHidden
                ? null
                : <div className={style.buttonsContactModal}>
                    <WhatsApp />
                    <Telegram />
                    <Phone />
                </div>
            }

        </>
    );
};
