import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import React, { useState } from 'react';
import style from './Price.module.css'
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { Content } from '../components/Layout/Content/Content'
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';
import { CallForm } from '../components/Form/CallForm';
import { useGetDoc } from '../Hook/useGetDoc';
import { CardServiceSmall } from '../components/Card/Card';
import { Modal } from '../components/Modal';



export const Price = () => {

    const { docs } = useGetDoc('services')
    const servicesForCompany = docs.filter(service => service.client === 'company')

    const [submitted, setSubmitted] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    console.log(openModal)



    return (
        <>
            <Header />

            <main>

                <Breadcrumbs levelTwo={'Тарифы и цены'} levelTwoURL='blog' />

                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <FirstBlock
                        title={'Тарифы и цены'}
                        lider={'Ознакомьтесь с нашими тарифами, чтобы заказать услуги промышленных альпинистов по выгодным ценам'}
                        // button={'Заказать звонок'}
                        img={'/IconIllustration/companyService_sotrudnichestvo.png'} />
                </Content>

                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >

                    <div className={style.content}>
                        <div className={style.tables}>


                            <div className={style.service}>
                                <h2 className={style.title}>Уборка снега с крыш</h2>
                                <UborkaSnega />
                            </div>


                            <div className={style.service}>
                                <h2 className={style.title}>Клининговые услуги</h2>
                                <div className={`${style.tableStyle} ${style.abonent}`}>
                                    <p className={`${style.description} ${style.abonentSescription}`}>Aбонентское обслуживание</p>
                                    <h3>Мытье окон</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr className={style.row}>
                                                    <td className={`${style.col} ${style.price}`}><span className={style.smallText}></span></td>
                                                    <td className={`${style.col} ${style.price}`}>1 раз<span className={style.smallText}> – Весна</span></td>
                                                    <td className={`${style.col} ${style.price}`}>2 раза<span className={style.smallText}> – Весна, Лето</span></td>
                                                    <td className={`${style.col} ${style.price}`}>3 раза<span className={style.smallText}> – Весна, Лето, Осень</span></td>
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>до 500<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 60 ', 'от 112 ', 'от 135 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>500-1000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 55 ', 'от 95 ', 'от 125 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>1000-2000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 50 ', 'от 85 ', 'от 115 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>2000+<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 46 ', 'от 78 ', 'от 105 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className={`${style.tableStyle}`}>
                                    <p className={`${style.description} ${style.abonentSescription}`}>Разовое обслуживание</p>
                                    <h3>Мытье окон</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>

                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>до 500<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 60 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>500-1000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 55 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>1000-2000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 50 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>2000+<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 46 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Мытье окон после строительства</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>до 500<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 75 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>500-1000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 70 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>1000-2000<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 65 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                                <tr>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}>2000+<span className={style.smallText}> кв.м.</span></td>
                                                    {['от 60 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className={`${style.tableStyle}`}>
                                    <h3>Очистка стен от высолов и растворов</h3>
                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Очистка стен от высолов на кирпичной кладке</td> <td className={`${style.col} ${style.price}`}>150-250 <span className={style.smallText}>₽/кв.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Очистка стен от цементного раствора на кирпичной кладке </td> <td className={`${style.col} ${style.price}`}>от 200 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className={style.service}>
                                <h2 className={style.title}>Ремонт и покраска</h2>
                                <div className={`${style.tableStyle}`}>
                                    <h3>Ремонт фасада</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Косметический ремонт штукатурного фасада</td> <td className={`${style.col} ${style.price}`}>400-700 <span className={style.smallText}>₽/кв.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Средний ремонт штукатурного фасада </td> <td className={`${style.col} ${style.price}`}>700-900 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Капитальный ремонт штукатурного фасада(с частичной отбивкой штукатурки) </td> <td className={`${style.col} ${style.price}`}>2000 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Капитальный ремонт штукатурного фасада(с полной отбивкой штукатурки) </td> <td className={`${style.col} ${style.price}`}>2300 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Покраска фасада</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Покраска фасада (новостройка)</td> <td className={`${style.col} ${style.price}`}>90 <span className={style.smallText}>₽/кв.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Покраска фасада после ремонта </td> <td className={`${style.col} ${style.price}`}>130 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Покраска крыши</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Грунтовка крыши</td> <td className={`${style.col} ${style.price}`}>150 <span className={style.smallText}>₽/кв.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Покраска крыши (1 слой) </td> <td className={`${style.col} ${style.price}`}>150 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Зачистка крыши от ржавчины (щетками, болгаркой) </td> <td className={`${style.col} ${style.price}`}>160 <span className={style.smallText}>₽/кв.м.</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>


                            <div className={style.service}>
                                <h2 className={style.title}>Монтажные работы</h2>
                                <div className={`${style.tableStyle}`}>
                                    <h3>Монтаж кондиционеров</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Монтаж внешнего блока до 3 кВт</td> <td className={`${style.col} ${style.price}`}>5000 <span className={style.smallText}>₽</span> </td> </tr>
                                                <tr> <td className={style.col}>Монтаж внешнего блока от 3-6 кВт </td> <td className={`${style.col} ${style.price}`}>7000 <span className={style.smallText}>₽</span></td> </tr>
                                                <tr> <td className={style.col}>Монтаж внешнего блока 6 кВт </td> <td className={`${style.col} ${style.price}`}>8000 <span className={style.smallText}>₽</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3>Монтаж водостоков</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Первичный монтаж</td> <td className={`${style.col} ${style.price}`}>450 <span className={style.smallText}>₽/п.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Монтаж на готовые крепления </td> <td className={`${style.col} ${style.price}`}>500 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Монтаж водосточного желоба </td> <td className={`${style.col} ${style.price}`}>400 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Замена водосточной воронки </td> <td className={`${style.col} ${style.price}`}>800 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Установка крышки на водосточную воронку </td> <td className={`${style.col} ${style.price}`}>300 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Демонтажные работы </td> <td className={`${style.col} ${style.price}`}>50% <span className={style.smallText}>от монтажа</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Монтаж воздуховодов</h3>
                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr> <td className={style.col}>Диаметр: 200-400 мм</td> <td className={`${style.col} ${style.price}`}>400 <span className={style.smallText}>₽/п.м.</span> </td> </tr>
                                                <tr> <td className={style.col}>Диаметр: 400-600 мм</td> <td className={`${style.col} ${style.price}`}>600 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Диаметр: 600-800 мм</td> <td className={`${style.col} ${style.price}`}>900 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Диаметр: 800-1000 мм </td> <td className={`${style.col} ${style.price}`}>1200 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                                <tr> <td className={style.col}>Ремонт, герметизация, замена крепежа воздуховодов </td> <td className={`${style.col} ${style.price}`}>от 7000 <span className={style.smallText}>₽/п.м.</span></td> </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className={style.service}>
                                <h2 className={style.title}>Герметизация и утепление</h2>
                                <div className={`${style.tableStyle}`}>
                                    <h3>Герметизация швов</h3>

                                    <div className={style.scrollTable}>
                                        <table className={style.table}>
                                            <tbody>
                                                <tr>
                                                    <td className={`${style.col} ${style.row}`}></td>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}> до 200 <span className={style.smallText}>п.м.</span> </td>
                                                    <td className={`${style.col} ${style.price} ${style.row}`}> более 200  <span className={style.smallText}>п.м.</span> </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.col}>Первичная герметизация(вилатерм+мастика)</td>
                                                    <td className={`${style.col} ${style.price}`}>300 <span className={style.smallText}>₽/п.м.</span> </td>
                                                    <td className={`${style.col} ${style.price}`}>280 <span className={style.smallText}>₽/п.м.</span> </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.col}>Промазка шва мастикой</td>
                                                    <td className={`${style.col} ${style.price}`}>290 <span className={style.smallText}>₽/п.м.</span> </td>
                                                    <td className={`${style.col} ${style.price}`}>270 <span className={style.smallText}>₽/п.м.</span> </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.col}>Вторичная герметизация, со вскрытием шва</td>
                                                    <td className={`${style.col} ${style.price}`}>430 <span className={style.smallText}>₽/п.м.</span> </td>
                                                    <td className={`${style.col} ${style.price}`}>380 <span className={style.smallText}>₽/п.м.</span> </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.col}>Теплый шов (пена,вилатерм, герметик)</td>
                                                    <td className={`${style.col} ${style.price}`}>650 <span className={style.smallText}>₽/п.м.</span> </td>
                                                    <td className={`${style.col} ${style.price}`}>600 <span className={style.smallText}>₽/п.м.</span> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>


                        <div className={style.rightBlock}>
                            <div className={style.form}>
                                <CallForm
                                setSubmitted={setSubmitted} 
                                buttonsHidden={true}
                                />
                            </div>
                            {submitted && <Modal setActive={setSubmitted} submitted={submitted} />}

                        </div>

                    </div>
                </Content>


            </main>

            <Footer />
        </>
    );
};




export const UborkaSnega = () => {
    return (
        <div className={style.serviceGroup}>
            <div className={`${style.tableStyle} ${style.abonent}`}>
                <p className={`${style.description} ${style.abonentSescription}`}>Абонентское обслуживание</p>

                <h3>Полная уборка снега</h3>
                <p className={style.description}>+ уборка наледи и сосулек (2 раза в месяц)</p>

                <div className={style.scrollTable}>
                    <table className={style.table}>
                        <tbody>
                            <tr className={style.row}>
                                {['до 500 ', '500-1000 ', '1000+ '].map(row => (<th className={`${style.col} ${style.price}`}> {row} <span className={style.smallText}>кв.м.</span></th>))}</tr>
                            <tr>
                                {['180 ', '160 ', '140 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/кв.м.</span></td>))}</tr>
                        </tbody>
                    </table>
                </div>

                <h3>Уборка снега по периметру</h3>
                <p className={style.description}>На расстояние 1.5м от края свеса (2 раза в месяц)</p>

                <div className={style.scrollTable}>
                    <table className={style.table}>
                        <tbody>
                            <tr className={style.row}>
                                {['до 100 ', '100-150 ', '150-200 ', '200-300 ', '300-900 ', '900+ '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>п.м.</span></td>))}</tr>
                            <tr>
                                {['420 ', '400 ', '335 ', '270 ', '180 ', '150 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/п.м.</span></td>))}</tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div className={style.tableStyle}>
                <p className={`${style.description} ${style.abonentSescription}`}>Разовое обслуживание</p>
                <h3>Полная уборка снега</h3>
                <p className={style.description}>+ уборка наледи и сосулек</p>

                <div className={style.scrollTable}>
                    <table className={style.table}>
                        <tbody>
                            <tr className={style.row}>
                                {['до 200 ', '200-500 ', '500-1000 ', '1000+'].map(row => (<th className={`${style.col} ${style.price}`}> {row} <span className={style.smallText}>кв.м.</span></th>))}</tr>
                            <tr>
                                {['80 ', '70 ', '65 ', '60'].map(row => (<th className={`${style.col} ${style.price}`}> {row} <span className={style.smallText}>₽/кв.м.</span></th>))}</tr>
                        </tbody>
                    </table>
                </div>

                <h3 >Уборка снега по периметру</h3>
                <p className={style.description}>На расстояние 1.5м от края свеса</p>

                <div className={style.scrollTable}>
                    <table className={style.table}>
                        <tbody>
                            <tr className={style.row}>
                                {['до 100 ', '100-150 ', '150-300 ', '300-500 ', '500-900 ', '900+ '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>п.м.</span></td>))}</tr>
                            <tr>
                                {['120 ', '100 ', '90 ', '85 ', '80 ', '70 '].map(row => (<td className={`${style.col} ${style.price}`}>{row}<span className={style.smallText} style={{ whiteSpace: 'nowrap' }}>₽/п.м.</span></td>))}</tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};
