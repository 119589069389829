import React from 'react';
import style from './HomePage.module.css'
import { Header } from '../components/HeaderFooter/header'
import { Footer } from '../components/HeaderFooter/footer';
import { CardClients, CardProject } from '../components/Card/Card';
import { useGetDoc } from '../Hook/useGetDoc';
import { localDataService } from '../Data/LocalDataService';
import { Button } from '../components/Button/button';
import { SalePeople } from '../components/Banners/SalePeople';
import { FirstBlockHomepageStyle } from '../components/Layout/FirstBlock/FirstBlock';
import { Content } from '../components/Layout/Content/Content';
import { TitleBlock } from '../components/Layout/TitleBlock/TitleBlock';



const clients = {
    people:
    {
        image: '/img/homepage_4people.jpg',
        title: 'Для жителей',
        button: '/people-service/germetizaciya-i-uteplenie',
        link: true,
        services: [
            {
                title: 'Мытье окон',
                url: '/people-service/moyka-okon',
            },
            {
                title: 'Герметизация и утепление стен',
                url: '/people-service/germetizaciya-i-uteplenie',
            },
            {
                title: 'Монтаж кондиционеров',
                url: '/people-service/montazh-kondicionerov',
            }
        ]

    },
    company:
    {
        image: '/img/homepage_4company.jpg',
        title: 'Для компаний',
        button: '/company-service',
        link: true,
        services: [
            {
                title: 'Мытье окон',
                url: '/company-service/myte-okon',
            },
            {
                title: 'Послестроительное мытье зданий',
                url: '/',
            },
            {
                title: 'Герметизация и утепление',
                url: '/company-service/germetizaciya',
            }
        ]

    },
    helper: {
        image: '/img/homepage_4helper.jpg',
        title: 'Помощь и спасение',
        button: '/people-service/sluzhba-pomoschi-i-spaseniya',
        link: false,
    }
}




export const HomePage = () => {

    const { docs } = useGetDoc('projects')
    const company = localDataService.filter(unit => unit.type === 'companyService')

    return (
        <>
            <Header />

            <main>

                <FirstBlockHomepageStyle
                    image={'/img/homepage_cover.jpg'}
                    title={'Промышленные альпинисты в Санкт-Петербурге и Ленинградской области'}
                    lider={'Обслуживаем, ремонтируем и моем коммерческие, промышленные, жилые комплексы'}
                    backgroundColor={'var(--bg-darkBlue)'}
                    IconRight={'arrowR'}
                    btn1={'Услуги для жителей'}
                    btn2={'Услуги для компаний'}
                    btn1url={'/people-service/germetizaciya-i-uteplenie'}
                    btn2url={'/company-service'}

                />

                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}

                >
                    <div className={style.clients}>
                        <TitleBlock
                            title={'Услуги высотных работ'}
                            description={'Мы предлагаем широкий спектр высотных работ в Санкт-Петербурге и Лениградской области'}
                        />

                        <div className={style.clientsCard}>
                            <CardClients client={clients.people} />
                            <CardClients client={clients.company} />
                            <CardClients client={clients.helper} />
                        </div>
                    </div>
                </Content>



                {/* <Content
                    contentWidth={'cnt-width-m'}
                    contentMargin={'cnt-margin-m'}
                >
                    <SalePeople more={true} />
                </Content> */}




                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentWidth={'cnt-width-m'}
                    contentMargin={'cnt-margin-0'}
                >
                    <div className={style.projects}>
                        <TitleBlock
                            title={'Наши проекты'}
                            description={'Выполненные нами услуги высотных работ в Санкт-Петербурге и Ленинградской области методом промышленного альпинизма'}
                        />
                        <div className={style.projectCard}>
                            {docs.slice(0, 4).map(project => <CardProject project={project} key={project.link} url={'projects'} />)}
                        </div>
                        <a href='/projects' className={style.buttonProject}>
                            <Button
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--m"}
                                IconRight={'arrowR'}
                                IconColor={'var(--green)'}
                            >Все проекты</Button>
                        </a>
                    </div>
                </Content>

                {/* <FaqBlock faq={faq} /> */}

            </main>

            <Footer />

        </>

    );
};
