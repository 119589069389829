import React from 'react';
// import style from './Content.module.css'
import './Content.css'

const backgroundPadd = [
    'bg-padding-l',
    'bg-padding-m',
    'bg-padding-s',
    'bg-padding-0',
]

const contentMarg = [
    'cnt-margin-l',
    'cnt-margin-m',
    'cnt-margin-s',
    'cnt-margin-xs',
    'cnt-margin-xxs',    
    'cnt-margin-0', 
]

const contentSize = [
    'cnt-width-m',
    'cnt-width-s',

]


export const Content = ({
    children,
    contentMargin,
    contentWidth,
    backgroundPadding,
    backgroundColor,
}) => {

    const bgPadd = backgroundPadd.includes(backgroundPadding) ? backgroundPadding : [0]
    const cntMarg = contentMarg.includes(contentMargin) ? contentMargin : [0]
    const cntWidth = contentSize.includes(contentWidth) ? contentWidth : [0]

    return (

        <>
            {backgroundColor
                ?
                <div
                    className={`${bgPadd}`}
                    style={{ backgroundColor: `${backgroundColor}` }}
                >
                    <div className={`${cntWidth} ${cntMarg}`}>
                        {children}
                    </div>
                </div>
                :
                <div className={`${cntWidth} ${cntMarg}`}>
                    {children}
                </div>
            }

        </>

    );
};


// export const ContentNew = ({ children, backgroundColor, smallPadding, notMargin }) => {
//     return (

//         <>
//             {!notMargin
//                 ?
//                 <>
//                     {backgroundColor
//                         ?
//                         <div
//                             className={smallPadding ? style.containerSmallPadding : style.container}
//                             style={
//                                 {
//                                     backgroundColor: `${backgroundColor}`,
//                                 }
//                             }
//                         >
//                             <div
//                                 className={style.content}
//                             >
//                                 {children}
//                             </div>
//                         </div>
//                         :
//                         <div
//                             className={smallPadding ? style.onlyContentSmallPadding : style.onlyContent}>
//                             {children}
//                         </div>
//                     }
//                 </>
//                 :
//                 <div
//                     className={style.onlyContentNotMargin}>
//                     {children}
//                 </div>
//             }
//         </>

//     );
// };