import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import { HomePage } from "./HomePage/HomePage";
import { Career } from './Career/career'
import { SearchPromalp } from './SearchPromalp/SearchPromalpPage'
import { About } from './About/About'
import { Contact } from './Contact'

import { LigaDobra } from './LigaDobra/LigaDobra';

import { Admin } from "./Admin/Admin";

import { CompanyServices } from './Company/Services'
import { CompanyServiceSinglepage } from './Singlepage/Service';

import { CompanyProjects } from "./Company/Projects";
import { ClientPersonalData } from './ClientPersonalData/ClientPersonalData'
import { AuthContextProvider } from "./Authentication/AuthContext";
import { RequireAuth } from "./Authentication/RequireAuth";
import { Login } from "./Authentication/Login";
import { PeopleService } from "./Singlepage/PeopleService";

import { AddService } from './Admin/AddService/AddService'
import { AddProject } from './Admin/AddProject/AddProject'
import { AddNews } from './Admin/AddNews/AddNews'
import { GetOrderForms } from "./Admin/GetOrderForms/GetOrderForms";
import { ServiceSectionContextProvider } from "./Context/ServicesSectionContext";
import { ServiceCollectionContextProvider } from "./Context/useServiceCollectionContext";
import { ProjectSinglepage } from "./Singlepage/Project";
import { ProjectsCollectionContextProvider } from "./Context/useProjectsCollectionContext";
import { BlogSectionCollectionContextProvider } from "./Context/useBlogSectionCollectionContext";

import { Blog } from './Blog/Blog'
import { BlogSinglepage } from './Singlepage/BlogSinglepage'
import { BlogSectionContextProvider } from "./Context/BlogSectionContext";
import { Price } from "./Price/Price";
import { FAQPage } from "./FAQ/FAQPage";
import { FAQCompany, FAQPeople} from "./FAQ/FAQ";
import { OrderForm } from "./Order form/OrderForm";


function App() {


  return (
    <>
      <AuthContextProvider >
        <ServiceSectionContextProvider>
          <ServiceCollectionContextProvider>
            <ProjectsCollectionContextProvider>
              <BlogSectionCollectionContextProvider>
                <BlogSectionContextProvider>

                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="career" element={<Career />} />
                    <Route path="search-promalp" element={<SearchPromalp />} />
                    <Route path="about" element={<About />} />
                    <Route path="order-form" element={<OrderForm />} />

                    <Route path="liga-dobra" element={<LigaDobra />} />
                    <Route path="liga-dobra/:link" element={<ProjectSinglepage />} />

                    <Route path="company-service" element={<CompanyServices />} />
                    <Route path="people-service/:link" element={<PeopleService />} />
                    <Route path="company-service/:link" element={<CompanyServiceSinglepage />} />

                    <Route path="projects" element={<CompanyProjects />} />
                    <Route path="projects/:link" element={<ProjectSinglepage />} />

                    <Route path="login" element={<Login />} />
                    <Route path="personal-data" element={<ClientPersonalData />} />

                    <Route path="blog" element={<Blog />} />
                    <Route path="blog/:link" element={<BlogSinglepage />} />

                    <Route path="price" element={<Price />} />
                    <Route path="faq/*" element={<FAQPage />}>
                      <Route path="q-people" element={<FAQPeople />} />
                      <Route path="q-company" element={<FAQCompany />} />
                    </Route>

                    <Route path="admin" element={<RequireAuth><Admin /></RequireAuth>} />
                    <Route path="admin/get-order" element={<GetOrderForms />} />
                    <Route path="admin/add-service" element={<AddService />} />
                    <Route path="admin/add-project" element={<AddProject />} />
                    <Route path="admin/add-news" element={<AddNews />} />

                  </Routes>

                </BlogSectionContextProvider>
              </BlogSectionCollectionContextProvider>
            </ProjectsCollectionContextProvider>
          </ServiceCollectionContextProvider>
        </ServiceSectionContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App