import { style } from '@mui/system';
import React from 'react';
import './ScrollHorizontal.css'


export const ScrollHorizontal = ({ id, children, btnLeft, btnRight, padding }) => {

    return (
        <div className='horizontal-scroll-container'>
            {btnLeft ? <ScrollLeft id={id} /> : null}
            <div
                className="horizontal-scroll"
                id={id}
                style={{ padding: `0 ${padding ? padding : 0}` }}
            >

                {children}
            </div>
            {btnRight ? <ScrollRight id={id} /> : null}

        </div>
    );
};


export const ScrollRight = ({ id }) => {
    const sliderRight = () => {
        var slider = document.getElementById(id)
        slider.scrollLeft = slider.scrollLeft + 200
    }
    return (
        <div className='batton-scroll-right' onClick={sliderRight}>
            <img src="/IconIllustration/ArRight.svg" alt="Scroll Right" />
        </div>
    );
};

export const ScrollLeft = ({ id }) => {
    const sliderLeft = () => {
        var slider = document.getElementById(id)
        slider.scrollLeft = slider.scrollLeft - 200
    }
    return (
        <div className='batton-scroll-left' onClick={sliderLeft}>
            <img src="/IconIllustration/ArLeft.svg" alt="Scroll Left" />
        </div>
    );
};





