import { Button } from '../components/Button'
import React from 'react';
import style from './about.module.css'
import { Header, HeaderTransparent } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';

import { CoverPages } from '../components/CoverPage/CoverPage'
import { GeneralMessage } from '../components/GeneralMessage/GeneralMessage';
import { Content } from '../components/Layout/Content/Content'





export const About = () => {

    return (
        <>

            <Header />

            <main>
                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.title}>
                        <h6>о компании</h6>
                        <h1>Обслуживаем, ремонтируем и моем коммерческие, промышленные, жилые комплексы</h1>
                    </div>
                </Content>

                <div className={style.image}>
                    <img src="/img/about_new.jpg" alt="" />
                </div>


                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <GeneralMessage img={'/IconIllustration/ocenka_clientov.png'}>
                        <h3 className={style.message}>Наша миссия – cодержать здания, строения и дома в чистоте и исправном техническом состоянии с помощью своевременного обслуживания. Качество и внешний вид зданий необходимы для общего благополучия и счастья владельцев, сообществ и арендаторов, которые живут, посещают, работают и проводят в них свое время.</h3>
                    </GeneralMessage>
                </Content>


                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.ceo}>
                        <div className={style.photoCEO}>
                            <img src="/img/about_ilshat.jpg" alt="" />
                        </div>
                        <div className={style.descriptionCEO}>
                            <p>Мой путь промышленного альпиниста начался в 2015 году в городе Уфа. Сейчас я живу в Санкт-Петербурге и руковожу компанией Высшая лига, где собрал команду профессионалов с доступом работы на высоте и универсальными навыками. Мой главный приоритет в работе любого уровня — исполнение услуги качественно и в оговорённые сроки. Ещё ни разу мы не подвели наших клиентов, и это большая победа для меня и моей команды.</p>
                            <h6>Ильшат Гайфуллин <br /> <span>Генеральные директор <br /> ООО Высшая лига</span> </h6>
                        </div>
                    </div>
                </Content>

                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >

                    <div className={style.career}>
                        <h6>Карьерные возможности</h6>
                        <h2>Мы развиваем командную работу, создавая сильные бригады специалистов</h2>
                        <p>Хотите стать частью команды Высшей лиги?</p>
                        <a href="/career">
                            <Button
                                buttonStyle={"btn--blue"}
                                buttonSize={"btn--m"}
                                IconRight={'arrowR'}
                                IconColor={'white'}
                            >Заполнить анкету
                            </Button>
                        </a>
                    </div>

                </Content>




            </main>

            <Footer />
        </>
    );
};










{/* <div className={style.containerNumbers}>
                    <div className={style.contentNumbers}>

                        <h1>В цифрах</h1>
                        <h3>В 2021-22 году наши альпинисты провели работы на 70 комплексах:</h3>
                        <div className={style.numbers}>
                            <div class={style.number}>
                                <div class={style.numberCover}>
                                    <img src="/img/about_clean.jpg" alt="" />
                                </div>
                                <div class={style.numberTitle}>
                                    <p>24</p>
                                    <h4>Электромонтажные, покрасочные и фасадные работы</h4>
                                </div>
                            </div>

                            <div class={style.number}>
                                <div class={style.numberCover}>
                                    <img src="/img/about_clean.jpg" alt="" />
                                </div>
                                <div class={style.numberTitle}>
                                    <p>45</p>
                                    <h4>Мытье окон и фасадов</h4>
                                </div>

                            </div>

                            <div class={style.number}>
                                <div class={style.numberCover}>
                                    <img src="/img/about_clean.jpg" alt="" />
                                </div>
                                <div class={style.numberTitle}>
                                    <p>212</p>
                                    <h4>Электромонтажные, покрасочные и фасадные работы</h4>
                                </div>
                            </div>

                            <div class={style.number}>
                                <div class={style.numberCover}>
                                    <img src="/img/about_blago.jpg" alt="" />
                                </div>
                                <div class={style.numberTitle}>
                                    <p>4</p>
                                    <h4>Благотворительные работы</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}