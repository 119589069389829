import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Radiobutton } from './checkAndRadio';
import style from './component.module.css'
// import onClickOutside from 'react-onclickoutside'

export const Select = ({ options, onChange, label, id, ...selectProps }) => {

    const [selected, setSelected] = useState('')
    const [active, setActive] = useState(false)
    const dropdown = useRef()


    return (

        <>
            <div className={style.select}>
                <div className={style.selectInput} onClick={() => setActive(!active)}>
                    {!selected &&
                        <div className={style.selectLabel}>{label}</div>
                    }
                    <span className={style.selectCurrent}>{selected}</span>
                    <div className={style.selectIcon}></div>
                </div>

                {active && (
                    <div className={style.dropdown} ref={dropdown}>
                        {options.map(option => (
                            <div
                                onClick={() => {
                                    setSelected(option)
                                    setActive(!active)
                                    onChange(option)
                                }}
                                onChange={onChange}
                                className={style.selectItem}
                                key={option}
                            >
                                {option}
                            </div>
                        ))
                        }
                    </div >
                )}
            </div>
        </>

    )
}

export const SelectRadiobutton = ({ options, onChange, id, ...selectProps }) => {

    const [selected, setSelected] = useState('Когда мыли окна в последний раз')
    const [active, setActive] = useState(false)
    const dropdown = useRef()


    return (

        <>
            <div className={style.select}>
                <div className={style.selectLabel}>{options.label}</div>
                <div className={style.selectInput} onClick={() => setActive(!active)}>
                    <span className={style.selectCurrent}>{selected}</span>
                    <div className={style.selectIcon}></div>
                </div>
                {active && (
                    <div className={style.dropdown} ref={dropdown}>
                        {options.map(option => {
                            return <>
                                <Radiobutton
                                    type={option.type}
                                    name={option.name}
                                    id={option.id}
                                    value={option.value}
                                    label={option.label}
                                // onChange={handleRadio}
                                />
                                <div
                                    onClick={() => {
                                        setSelected(option)
                                        setActive(!active)
                                        onChange(option)
                                    }}
                                    onChange={onChange}
                                    className={style.selectItem}
                                    key={option}

                                >
                                    {option}
                                </div>
                            </>
                        })
                        }
                    </div >
                )}
            </div>
        </>

    )
}
