import React from 'react';
import style from './career.module.css'
import { PlusCard } from '../components/PlusCard/PlusCard';
import { Header } from '../components/HeaderFooter/header'
import { Footer } from '../components/HeaderFooter/footer';
import { FormBlock } from '../components/Form/formBlock';
import { AnketaPromalpForm } from '../components/Form/AnketaPromalp';
import { Button } from '../components/Button/button';
import { GeneralMessage } from '../components/GeneralMessage/GeneralMessage';
import { Content } from '../components/Layout/Content/Content'
import { FirstBlockHomepageStyle } from '../components/Layout/FirstBlock/FirstBlock';
import { ScrollToBlock } from '../components/Helper/ScrollTo';
import { useRef } from 'react';

const cennosti = [
    {
        icon: '/IconIllustration/bezopasnost.jpg',
        title: 'Безопасность',
        description: 'Мы серьезно относимся к безопасности своих альпинистов и качеству снаряжения. В работе используем только сертифицированное снаряжение, без износа и вышедшего срока службы.',
        id: 1,
    },
    {
        icon: '/IconIllustration/effectivnost.jpg',
        title: 'Эффективность',
        description: 'На каждом объекте мы планируем, проверяем и корректируем реализацию, чтобы исключить потери и ненужные затраты.',
        id: 1,
    },
    {
        icon: '/IconIllustration/otvetstvennost.jpg',
        title: 'Ответственность',
        description: 'Каждый из наших сотрудников берет на себя ответственность за работу, которую мы выполняем, за результаты, которые мы достигаем и за ценности, которые мы предсталяем перед заказчиком, обществом и окружающей средой.',
        id: 1,
    },
    {
        icon: '/IconIllustration/etika_komandi.jpg',
        title: 'Этика команды',
        description: 'Мы поощряем культуру поддержки, взаимного уважения и сотрудничества среди наших сотрудников. ',
        id: 1,
    },
]

export const Career = () => {

    const form = useRef()

    return (
        <>
            <Header />

            <main>

                <FirstBlockHomepageStyle
                    image={'/img/career.jpg'}
                    title={'Карьера'}
                    lider={'Мы набираем в команду промышленных альпинистов по всей России, чтобы предложить качественный сервис в сфере промальпа везде, где это может потребоваться. Если вы готовы сотрудничать с компанией Высшая лига, находясь в любом городе России, пожалуйста заполните форму. Мы свяжемся с вами.'}
                    backgroundColor={'var(--bg-darkBlue)'}
                    btn1={'Заполнить анкету'}
                    onClickBtn1={() => ScrollToBlock(form)}

                />


                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.cennosti}>
                        <h1>Наши ценности</h1>
                        <div className={style.cennost}>
                            {cennosti.map(cennost => <PlusCard cennost={cennost} key={cennost.id} />)}
                        </div>
                    </div>
                </Content>


                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <GeneralMessage img={'/IconIllustration/ocenka_clientov.png'}>
                        <h3 className={style.bannerText}>Ваша работа оценивается клиентами после сотрудничества и будет представлена в виде отчетов. Высокая оценка исполнения заказа и положительные отзывы будут влиять на дальнейшие предложения</h3>
                    </GeneralMessage>
                </Content>


                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.promalpSPB}>
                        <div className={style.promalpTitle}>
                            <div className={style.gerbSPB}>
                                <img src="/IconIllustration/gerb_SPB.jpg" alt="" />
                            </div>
                            <h3>Для промышленных альпинистов из Санкт-Петербурга</h3>
                            <p>Хотите работать в районе проживания? Мы найдем для вас работу в этой части города. Компания Высшая лига собирает команды специалистов с доступом, для работы в различных районах города Санкт-Петербурга. Мы укомплектовываем новые бригады для расширения территории оказания услуг методом промышленного альпинизма.</p>
                            <Button
                                buttonStyle={"btn--blue"}
                                buttonSize={"btn--m"}
                                // IconRight={'arrowDown'}
                                onClick={() => ScrollToBlock(form)}
                            >Заполнить анкету
                            </Button>
                        </div>
                        <div className={style.promalpMaps}>
                            <img src='/IconIllustration/maps.jpg' alt="" />
                        </div>
                    </div>
                </Content>


                <Content
                    backgroundColor={'var(--grey-superDark)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div
                        className={style.form}
                        ref={form}
                    >
                        <AnketaPromalpForm />
                    </div>
                </Content>

            </main>

            <Footer />
        </>
    );
};
