import React, { useEffect } from 'react';
import style from './GetOrderForms.module.css'
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useState } from 'react';


export const GetCompanyCollection = () => {

    const [data, setData] = useState([])

    const getData = async () => {

        const form = query(collection(db, "Company"), orderBy('date', "desc"))
        const querySnapshot = await getDocs(form);

        const docs = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            docs.push(doc.data())
        });
        setData(docs)
    }


    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3 className={style.title}>От компаний</h3>

            <table className={style.table}>
                <tr>
                    <th>Дата</th>
                    <th>Название компании</th>
                    <th>Имя</th>
                    <th>Телефон</th>
                    <th>Email</th>
                    <th>Сроки проекта</th>
                    <th>Комментарий</th>
                    <th>Техническое задание</th>
                </tr>

                {data.map((item) => (
                    <tr key={item.id} >
                        <td style={{ textAlign: 'center' }}>{item.date}</td>
                        <td style={{ textAlign: 'center' }}>{item.company}</td>
                        <td style={{ textAlign: 'center' }}>{item.name}</td>
                        <td style={{ textAlign: 'center' }}>{item.phone}</td>
                        <td style={{ textAlign: 'center' }}>{item.email}</td>
                        <td style={{ textAlign: 'center' }}>{item.deadline}</td>
                        <td>{item.comment}</td>
                        <td style={{ textAlign: 'center' }}><a href={item.file} target='_blank'>Скачать</a></td>

                    </tr>
                ))}

            </table>
        </div>
    );
};
