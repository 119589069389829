import style from './Admin.module.css';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useAuth } from '../Authentication/AuthContext';
import { NavLink } from 'react-router-dom';
import { Header } from '../components/HeaderFooter/header';


const adminMenu = [
    {
        id: 'peopleOrder',
        link: 'get-order',
        value: 'Заявки от физлиц',
    },
    {
        id: 'addService',
        link: 'add-service',
        value: 'Добавить сервис',
    },
    {
        id: 'addProject',
        link: 'add-project',
        value: 'Добавить проект',
    },
    {
        id: 'addNews',
        link: 'add-news',
        value: 'Добавить новость',
    },
]

export const Admin = () => {
    const { user } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (user) {
            navigate('/admin')
        }
    }, [])


    return (
        <>
            <Header />

            <main>
                <div className={style.chapter}>
                    {adminMenu.map(unit =>
                        <NavLink to={unit.link}>
                            <div className={style.chapterButton}>{unit.value}</div>
                        </NavLink>
                    )}
                </div>
            </main>


        </>
    );
};