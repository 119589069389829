import React from 'react';
import { useParams } from 'react-router-dom';
import style from './Singlepage.module.css'
import { SliderImage } from '../components/SliderImage/sliderImage';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { useGetDoc } from '../Hook/useGetDoc';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { Content } from '../components/Layout/Content/Content';
import { CardServiceOther } from '../components/Card/Card';



export const ProjectSinglepage = () => {

    const { docs } = useGetDoc('projects')

    const { link } = useParams()
    const project = docs.find((project) => project.link === link)

    return (
        <>
            <Header />

            <main>

                <Breadcrumbs levelTwo={'Проекты'} levelTwoURL='projects' levelThree={project?.client} />

                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.grid}>

                        <div className={style.title}>
                            <h2>{project?.client}</h2>
                            <p>{project?.title}</p>
                        </div>

                        <div className={style.content}>
                            {project
                                ? <div className={style.sliderImages}>
                                    <SliderImage imgs={project.images} />
                                </div>
                                : null
                            }
                            <div className={style.textStyle}>
                                <div dangerouslySetInnerHTML={{ __html: project?.content }}></div>
                            </div>
                        </div>

                        <div className={style.rightBlock}>

                            <div className={style.ad}>
                            </div>

                        </div>



                    </div>

                </Content>

            </main>

            <Footer />
        </>


    );
};