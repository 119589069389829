import React from 'react';
import './button.css'


const Icons = [
  { icon: '/IconIllustration/SArrowRight.svg', id: 'arrowR', },
  { icon: '/IconIllustration/SArrowUpRight.svg', id: 'arrowUpR', },
  { icon: '/IconIllustration/Messenger_whatsappFlat.svg', id: 'whatsapp', },
  { icon: '/IconIllustration/Social_TelegramFlat.svg', id: 'telegram', },
  { icon: '/IconIllustration/phone.svg', id: 'phone', },
  { icon: '/IconIllustration/hotCall.svg', id: 'hotCall', },
  { icon: '/IconIllustration/basket.svg', id: 'basket', },
]


const Styles = [
  "btn--blue",
  "btn--grey",
  "btn--white",
  'btn--green',
  'btn--greyBlackText',
  'btn--black',
  'btn--red',

]

const Sizes = [
  "btn--l",
  "btn--m",
  "btn--s",
]

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  IconLeft,
  IconRight,
  IconColor,
  disabled }) => {

  const checkButtonStyle = Styles.includes(buttonStyle) ? buttonStyle : [0]
  const checkButtonSize = Sizes.includes(buttonSize) ? buttonSize : [0]
  const IconLeftIndex = Icons.findIndex(icon => icon.id === IconLeft)
  const IconRightIndex = Icons.findIndex(icon => icon.id === IconRight)

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {IconLeft
        ? <div className='icon'>
          <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: `${IconColor}`,
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            maskImage: `url(${Icons[IconLeftIndex]?.icon})`,
            WebkitMaskImage: `url(${Icons[IconLeftIndex]?.icon})`,

          }} />
        </div>
        : null
      }
      <p className='text-button'>{children}</p>
      {IconRight
        ? <div className='icon'>
          <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: `${IconColor}`,
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            maskImage: `url(${Icons[IconRightIndex]?.icon})`,
            WebkitMaskImage: `url(${Icons[IconRightIndex]?.icon})`,

          }} />
        </div>
        : null
      }
    </button>
  );
};




export const WhatsApp = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'https://wa.me/79628120000'}
        target="_blank"
        className='roundButton'
      >
        <img src="/IconIllustration/messenger_WhatsApp.svg" alt="" />
      </a>
    </>
  );
};

export const WhatsAppButton = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'https://wa.me/79628120000'}>
        <Button
          type="button"
          buttonStyle={"btn--green"}
          buttonSize={"btn--m"}
          IconRight={'whatsapp'}
          IconColor={'white'}
        >WhatsApp
        </Button>
      </a>
    </>
  );
};

export const Telegram = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'https://t.me/vishayliga13'}
        target="_blank"
        className='roundButton'
      >
        <img src="/IconIllustration/messenger_Telegram.svg" alt="" />
      </a>
    </>
  );
};

export const Phone = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'tel:+79628120000'}
        target="_blank"
        className='roundButton'
      >
        <img src="/IconIllustration/messenger_Phone.svg" alt="" />
      </a>
    </>
  );
};

export const Instagram = ({ link }) => {

  return (
    <a href="https://www.instagram.com/vishayliga13"
      target="_blank"
      className='roundButton'
    >
      <img src="/IconIllustration/social_InstagramWhite.svg" />
    </a>
  );
};

export const YouTube = () => {

  return (
    <a href="https://www.youtube.com/channel/UC2SjLn3udptK83N8AV9561A"
      target="_blank"
      className='roundButton'
    >
      <img src="/IconIllustration/social_YouTubeWhite.svg" />
    </a>

  );
};

export const Vkontakte = () => {

  return (
    <a href="https://vk.com/vishayliga13"
      target="_blank"
      className='roundButton'
    >
      <img src="/IconIllustration/social_VKWhite.svg" />
    </a>


  );
};




