import React from 'react';
import style from './PlusCard.module.css'

export const PlusCard = ({cennost}) => {
    return (
            <div className={style.cennost}>
                <PlusCardCover icon = {cennost.icon}/>
                <h3>{cennost.title}</h3>
                <p>{cennost.description}</p>
            </div>
    );
};

const PlusCardCover = ({icon = ""}) => {
    return (
        <div className={style.plusCardCover} style={{ backgroundImage: `url(${icon})` }} />
    );
};
