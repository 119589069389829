
import React from 'react';
import './card-people-situation.css'

export const CardPeopleSituation = ({situation}) => {
    return (
        <div className='card-situation-container' >
            <div className='card-situation-cover'>
                <CardPeopleCover image={situation.image}/>
            </div>
            
            <div className='card-situation'>
                <h4>{situation.title}</h4>    
                <p>{situation.description}</p>
            </div>
        </div>
    );
};

const CardPeopleCover = ({image = ""}) => {
    return (
        <div className='card-situation-cover-container'>
            <div className="card-situation-cover" style={{ backgroundImage: `url(${image})` }} />
        </div>

    );
};