import React, { useEffect, useRef } from 'react';
import style from './component.module.css'
import { useState } from 'react'

// export const Input = (props) => {

//     const { label, errorMessage, onChange, id, inputmode, checked, ...inputProps } = props

//     const [focused, setFocused] = useState(false)
//     const handleFocus = (e) => {
//         setFocused(true)
//     }


//     return (
//         <div className={style.formInput}>
//             <label htmlFor={id} className={style.inputLabel}>{label}</label>
//             <input className={style.input}
//                 {...inputProps}
//                 onChange={onChange}
//                 inputMode={inputmode}
//                 onBlur={handleFocus}
//                 focused={focused.toString()}
//             // required
//             />
//             <span className={style.errorMessage}>{errorMessage}</span>
//         </div>
//     );
// };

export const Input = ({
    label,
    placeholder,
    errorMessage,
    onChange,
    id,
    inputmode,
    checked,
    ...inputProps }) => {
        
        
    const [focused, setFocused] = useState(false)
    const handleFocus = (e) => {
        setFocused(true)
    }


    return (
        <div className={style.input}>
            <input
                {...inputProps}
                id={id}
                onChange={onChange}
                inputMode={inputmode}
                onBlur={handleFocus}
                focused={focused.toString()}
                placeholder = {placeholder}

            />
            <label htmlFor={id}>{label}</label>

            {errorMessage 
            ?<span className={style.errorMessage}>{errorMessage}</span>
            :null
            }
            
        </div>
    );
};


