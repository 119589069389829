import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { auth } from '../firebase-config' // экземпляр аутентификации

import
{
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth'
import { useNavigate } from 'react-router';


const AuthContext = createContext() // создаем контекст 

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    const signUp = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    const logIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logOut = (email, password) => {
        return signOut(auth)
    }

    useEffect(()=> { // меняет состояние аутентификации и представляет  текущег опользователя 
        const unsubscribe =  onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        })
        return () => {
            unsubscribe()
        }
    }, []) 

const value = {
    user,
    signUp,
    logIn,
    logOut
}

    return(
        <AuthContext.Provider
            value = {value}>
            {children}
        </AuthContext.Provider>  
    )
};

export const useAuth = () => { // ф для использования контекста, возвращает значение AuthContext
    return useContext(AuthContext)
}


// const value = {currentUser, signUp} // информация которую мы передаем вместе аутентификацией 



// export const AuthProvider = ({ children }) => {

//     const [user, setUser] = useState(null)

//     onAuthStateChanged(auth, (currentUser) => {
//         setUser(currentUser)
//     })

    
//     const login = async () => {
//         // setUser(user)
//         try {
//             const user = await signInWithEmailAndPassword(auth, emailLogin, passwordLogin)
//             console.log(user)
//         } catch (error) {
//             console.log(error.message)
//         }
//     }

//     const logout = async () => {
//         // setUser(null)
//         await signOut(auth)
//     }

//     return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
// };

// export const useAuth = () => { // возвращает значение AuthContext
//     return useContext(AuthContext)

// }