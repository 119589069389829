import style from './FAQ.module.css'
import React from 'react';
import { Header } from '../components/HeaderFooter/header';
import { Breadcrumbs } from '@mui/material';
import { Content } from '../components/Layout/Content/Content';
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';
import { Footer } from '../components/HeaderFooter/footer';
import { NavLink, Outlet } from 'react-router-dom';

export const FAQPage = () => {
    return (
        <>
            <Header />

            <main>

                <Content
                    backgroundColor={'var(--bg-darkBlue)'}
                    backgroundPadding={'bg-padding-s'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}

                >
                    <FirstBlock
                        title={'Вопрос-ответ'}
                        lider={'Если у вас есть какие-либо вопросы или вы хотите оставить предложение, лучший способ связаться с нами — через WhatsApp. Мы делаем все возможное, чтобы ответить как можно быстрее.'}
                        whiteText={true}
                        img={'/IconIllustration/FAQ.png'}

                        button={'Whats App'}
                        IconRight={'whatsapp'}
                        IconColor={'white'}
                        buttonStyle={"btn--green"}
                        buttonUrl={'https://wa.me/79628120000'}
                    />
                </Content>

                <Content
                    backgroundColor={"var(--grey)"}
                    backgroundPadding={'bg-padding-s'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.content}>
                        <div className={style.sideBar}>

                            <NavLink
                                to='q-people'
                                className={({ isActive }) => isActive ? `${style.linkActive}` : `${style.link}`}
                            >Для жителей
                            </NavLink>

                            <NavLink
                                to='q-company'
                                className={({ isActive }) => isActive ? `${style.linkActive}` : `${style.link}`}
                            >Для компаний
                            </NavLink>

                        </div>

                        <div className={style.faqs}>
                            <Outlet />
                        </div>

                        <div className={style.rightBlock}>
                            <p>Остались вопросы?<br /><span>Свяжитесь с нами, мы все расскажем</span> </p>
                        </div>
                    </div>

                </Content>

            </main>

            <Footer />

        </>
    );
};


