import { ref, uploadBytes } from '@firebase/storage';
import React, { useRef, useState } from 'react';
import { storage } from '../../../firebase-config';
import style from './component.module.css'

export const InputFile = (props) => {

    const { label, uploadFile, selectedFile, errorMessage, setSelectedFile, ...inputProps } = props


    const handleUploadFile = (e) => {
        setSelectedFile(e.target.files[0])
        uploadFile(e.target.files[0])
    }

    const fileRef = useRef(null)
    const handleRef = () => {
        fileRef.current.click()
    }

    return (

        <>
            <div className={style.file}>

                <input
                    {...inputProps}
                    type='file'
                    className={style.hidden}
                    onChange={handleUploadFile}
                    ref={fileRef}
                />

                <div className={style.buttonUploadFile} onClick={handleRef}>
                    <img src='/IconIllustration/AddFile.svg' />
                    <p>{label}</p>
                </div>

                {selectedFile && (

                    <>
                        <div className={style.filename}>
                            <div className={style.line}></div>
                            <div className={style.name}>{selectedFile.name}</div>

                        </div>
                    </>

                )}
            </div>

        </>
    );
};

export const InputFiles = ({ label, selectedFiles, setSelectedFiles }) => {

    const fileRef = useRef(null)
    const handleRef = () => {
        fileRef.current.click()
    }

    const handleChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const newFile = e.target.files[i]
            newFile['id'] = Math.random()
            setSelectedFiles((prevState) => [...prevState, newFile])
        }
    }
    return (

        <>
            <div className={style.formInput}>
                <div
                    className={style.fileButton}
                    onClick={handleRef}>{label}</div>
                <input
                    type='file'
                    className={style.hidden}
                    onChange={handleChange}
                    ref={fileRef}
                    multiple
                />
            </div>

            {selectedFiles && (
                selectedFiles.map(file =>
                    <div className={style.fileName} key={file.id}>{file.name}</div>
                )
            )}
        </>
    );
};