import React from 'react';
import style from './Projects.module.css'
import { AButton, Button } from '../components/Button';
import { useParams } from 'react-router';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { CompanyForm } from '../components/Form/Company';
import { FormBlock } from '../components/Form/formBlock';
import { CardProject } from '../components/Card/Card';
import { useGetDoc } from '../Hook/useGetDoc';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';
import { Content } from '../components/Layout/Content/Content'






export const CompanyProjects = () => {

    const { docs } = useGetDoc('projects')

    const scrollBottom = () => {
        var body = document.body
        body.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    return (
        <>
            <Header />

            <main>
                <Breadcrumbs levelTwo={'Проекты'} levelTwoURL='projects' />

                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <FirstBlock
                        title={'Наши проекты'}
                        lider={'Услуги для компаний, УК, ЖК от надежного лидера рынка промышленного альпинизма'}
                        button={'Заказать звонок'}
                        img={'/IconIllustration/companyService_sotrudnichestvo.png'}
                    />
                </Content>


                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.projects}>
                        {docs.map(project => <CardProject project={project} key={project.link} url={'projects'} />)}
                    </div>

                </Content>

                <Content
                    backgroundColor={'var(--grey-superDark)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.form}>
                        <CompanyForm formName={'Заказать звонок'} />
                    </div>
                </Content>



            </main>

            <Footer />
        </>

    );
};