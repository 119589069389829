import React, { useEffect } from 'react';
import style from './GetOrderForms.module.css'
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useState } from 'react';


export const GetAnketaCollection = () => {

    const [data, setData] = useState([])


    const getData = async () => {

        const form = query(collection(db, "Anketa"), orderBy('date', "desc"))
        const querySnapshot = await getDocs(form);

        const docs = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            docs.push(doc.data())
        });
        setData(docs)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3 className={style.title}>Анкеты</h3>
            <table className={style.table}>
                <tr>
                    <th>Дата</th>
                    <th>Имя</th>
                    <th>Город</th>
                    <th>Телефон</th>
                    <th>Email</th>
                    <th>Привязь</th>
                    <th>СпускСистема</th>
                    <th>СтрахСистема</th>
                    <th>Вахта</th>
                    <th>Удостоверение</th>
                    <th>Файл</th>
                </tr>

                {data.map((item) => (
                    <tr  className={style.tableItem} key={item.id} >
                        <td style = {{textAlign: 'center'}}>{item.date}</td>
                        <td style = {{textAlign: 'center'}}>{item.name}</td>
                        <td style = {{textAlign: 'center'}}>{item.city}</td>
                        <td style = {{textAlign: 'center'}}>{item.phone}</td>
                        <td style = {{textAlign: 'center'}}>{item.email}</td>
                        <td>{item.privyaz}</td>
                        <td>{item.spuskSystem}</td>
                        <td>{item.strahSystem}</td>
                        <td style = {{textAlign: 'center'}}>{item.vahta ? <img src="/IconIllustration/check_green.svg"/> : null}</td>
                        <td>{item.udostoverenie}</td>
                        <td style = {{textAlign: 'center'}}><a href={item.file} target='_blank'>Скачать</a></td>
                    </tr>
                ))}

            </table>
        </div>
    );
};
