import React from 'react';
import style from './component.module.css'

export const TextArea = (props) => {

    const { onChange, placeholder, label, ...inputProps } = props

    return (
        <div>
            {/* <p className={style.textareaLabel}>{label}</p> */}
            <textarea className={style.textarea}
                {...inputProps}
                onChange={onChange}
                placeholder={placeholder}
            ></textarea>
        </div>
    );
};

// 'Задайте вопрос или напишите ваши пожелания'