import React from 'react';
import { Button } from '../../Button';
import style from './FirstBlock.module.css'


export const FirstBlockHomepageStyle = ({
    title,
    lider,
    image,
    backgroundColor,
    IconRight,

    btn1,
    btn1url,
    onClickBtn1,

    btn2,
    btn2url,
    onClickBtn2,

}) => {

    const scrollBottom = () => {
        var body = document.body
        body.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    return (
        <div className={style.homepageStyle}>
            <div className={style.titleBox}>
                <div
                    className={style.homepageTitle}
                    style={{ backgroundColor: `${backgroundColor}` }}
                >
                    <h1>{title}</h1>
                    <p className={style.lider}>{lider}</p>
                    <div className={style.buttonsHomepage}>
                        {btn1
                            ?
                            <a href={btn1url}>
                                <Button
                                    buttonStyle={"btn--green"}
                                    buttonSize={"btn--m"}
                                    IconRight={IconRight}
                                    IconColor={'white'}
                                    onClick={onClickBtn1}
                                >{btn1}
                                </Button>
                            </a>
                            : null
                        }
                        {btn2
                            ?
                            <a href={btn2url}>
                                <Button
                                    buttonStyle={"btn--white"}
                                    buttonSize={"btn--m"}
                                    IconRight={IconRight}
                                    IconColor={'var(--text-black)'}
                                    onClick={onClickBtn2}
                                >{btn2}
                                </Button>
                            </a>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className={style.homepageImage}>
                <img src={image} alt={title} />
            </div>
        </div>
    );
};


export const FirstBlock = ({
    title,
    lider,
    img,
    onClick,
    whiteText,

    button,
    buttonUrl,
    buttonStyle,
    IconRight,
    IconColor,
}) => {

    const scrollBottom = () => {
        var body = document.body
        body.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    return (
        <div className={style.contentFirstBlock}>
            <div className={whiteText ? `${style.firstTitle} ${style.firstTitleWhite}` : style.firstTitle}>
                <h1>{title}</h1>
                <p className={style.lider}>{lider}</p>
                {button
                    ?
                    <a href={buttonUrl}target = '_blank' >
                        <Button
                            buttonStyle={buttonStyle ? buttonStyle : "btn--blue"}
                            buttonSize={"btn--m"}
                            IconRight={IconRight}
                            IconColor={IconColor ? IconColor : 'var(--text-black)'}
                            onClick={onClick}
                        >{button}
                        </Button>
                    </a>
                    : null
                }

            </div>
            <div className={style.image}>
                <img src={img} alt="" />
            </div>
        </div>
    );
};

