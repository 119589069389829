import React from 'react'
import { useParams } from 'react-router';
import './PeopleService.css'

import { Header } from '../components/HeaderFooter/header';
import { CardPeopleSituation } from '../components/PeopleCardSituation/card-people-situation'
import { ScrollHorizontal } from '../components/ScrollHorizontal/ScrollHorizontal';
import { Footer } from '../components/HeaderFooter/footer';
import { FeedbackBlock } from '../components/Feedback/feedbackBlock';

import { PeopleCleaning } from '../components/Form/PeopleCleaning';
import { useGetDoc } from '../Hook/useGetDoc';
import { PeopleUniversalForm } from '../components/Form/PeopleUniversal';
import { CardIconsServices, CardServiceSmall } from '../components/Card/Card';
import { localDataService } from '../Data/LocalDataService'
import { FaqBlock } from '../components/FAQ/faqBlock';
import { Button } from '../components/Button/button';
import { SalePeople } from '../components/Banners/SalePeople';
import { Content } from '../components/Layout/Content/Content'
import { TitleBlock } from '../components/Layout/TitleBlock/TitleBlock';
import { FAQ } from '../FAQ/FAQ';




export const PeopleService = () => {

    const { docs } = useGetDoc('services')

    const { link } = useParams()
    const service = docs.find((unit) => unit.link === link)

    const firebaseServices = docs.filter(service => service.client === 'people')
    const localService = localDataService.find(unit => unit.serviceName === service?.serviceName)
    console.log(localService)


    return (
        <>

            <Header />

            <main>

                <div className='iconService'>
                    <ScrollHorizontal padding={'20px'}>
                        {firebaseServices.map(service => <CardIconsServices service={service} key={service.serviceName} icon={localService.icon} />)}
                    </ScrollHorizontal>
                </div>

                <div className='coverDesktop'>
                    <div className="cover"
                        style={{
                            backgroundImage:
                                `linear-gradient( rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)
                          ),url(${service?.cover})`,
                            objectFit: 'cover',
                            backgroundPosition: 'center'
                        }}>

                        <div className='containerForm'>
                            <div className='peopleForm'>
                                {service?.serviceName === 'Мытье окон'
                                    ? <PeopleCleaning
                                        idCheckboxDirt={'DirtDesktop'}
                                        idCheckboxPersonData={'PersonDataDesktop'}
                                    />
                                    : <PeopleUniversalForm service={service} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='coverMobile'>
                    <div className="cover"
                        style={{
                            backgroundImage: `url(${service?.cover})`,
                            objectFit: 'cover',
                            backgroundPosition: 'center'
                        }}>
                    </div>
                    <div className='containerForm'>
                        {/* <div className='empty'></div> */}
                        <div className='peopleForm'>
                            {service?.serviceName === 'Мытье окон'
                                ? <PeopleCleaning
                                    idCheckboxDirt={'DirtMobile'}
                                    idCheckboxPersonData={'PersonDataMobile'}
                                />
                                : <PeopleUniversalForm service={service} />
                            }
                        </div>
                    </div>
                </div>

                {/* <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <SalePeople />
                </Content> */}

                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}

                >
                    <div className='about-services-people'>
                        <div className='cover-services-people'>
                            <img src={localService?.image} />
                        </div>
                        <div className='descriprion-services-people'>
                            <h3>{service?.description}</h3>
                        </div>
                    </div>
                </Content>


                {localService?.situations
                    ? <div>
                        <div className='situation-content'>
                            <h2>Реальные ситуации</h2>
                        </div>
                        <ScrollHorizontal id='situation-scroll'>
                            {localService?.situations.map(situation => <CardPeopleSituation situation={situation} key={situation.id} />)}
                        </ScrollHorizontal>

                    </div>
                    : null
                }

                {localService?.faq
                    ? <Content
                        backgroundColor={'var(--grey)'}
                        backgroundPadding={'bg-padding-m'}
                        contentMargin={'cnt-margin-0'}
                        contentWidth={'cnt-width-m'}
                    >
                        <TitleBlock
                            title={'Ответы на вопросы'}
                            description={'Если вы не нашли ответ на свой вопрос, задайте свой вопрос в WhatsApp'}
                        />
                        <div className='faqService'>
                            {localService.faq?.map(faq => <FAQ unit={faq} />)}
                        </div>
                    </Content>
                    : null
                }

                {localService?.feedback
                    ? <div className='feedbackAndFaq'>
                        <FeedbackBlock feedbacks={localService.feedback} />
                    </div>
                    : null
                }

                <div className='all-services-content'>
                    <div className="services-people">
                        {firebaseServices.map(service => <CardServiceSmall url={'people-service'} service={service} key={service.id} onClick={window.scrollTo(window.pageYOffset, 0)} />)}
                    </div>
                </div>


                <div className='contentNoService'>
                    <h3>Нет нужной услуги? Напишите нам </h3>
                    <a href="https://wa.me/79111377691">
                        <Button
                            type="button"
                            buttonStyle={"btn--green"}
                            buttonSize={"btn--m"}
                            IconRight={'whatsapp'}
                            IconColor={'white'}
                        >WhatsApp
                        </Button>
                    </a>
                </div>
            </main>

            <Footer />
        </>
    );
}