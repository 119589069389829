import React from 'react';
import style from './feedback.module.css'

export const FeedbackCard = ({feedback}) => {
    return (
        <div className={style.feedbackCard}>
            <p>{feedback.quote}</p>
            <p>{feedback.author}</p>
            <p>{feedback.company}</p>
        </div>
    );
};

  