import React, { useEffect, useRef, useState } from 'react';
import style from './AddService.module.css'

import { Input } from '../../components/Form/unitForm/input';
import { SelectGetID } from '../../components/Form/unitForm/SelectGetID';
import { InputFile } from '../../components/Form/unitForm/inputFile';

import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { db, storage } from '../../firebase-config';
import { addDoc, collection } from 'firebase/firestore';

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import translit from '../../components/Translit/Translit';
import { useBlogSectionCollectionContext } from '../../Context/useBlogSectionCollectionContext';
import { Header } from '../../components/HeaderFooter/header';
import { newsInputs } from './addNewsInputs';



export const AddNews = () => {

    const { title, altTextCover, cover, sponsoredLink, sponsor } = newsInputs
    const { blogSection } = useBlogSectionCollectionContext()

    const [serviceCover, setServiceCover] = useState(null)
    const [serviceCoverURL, setServiceCoverURL] = useState([])
    const [serviceImages, setServiceImages] = useState([])
    const [serviceImagesURL, setServiceImagesURL] = useState([])

    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const editor = useRef(null);
    const focusEditor = () => { editor.current.focus() }
    const [content, setContent] = useState('')

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();


    const [values, setValues] = useState({
        cover: null,
        title: '',
        sponsoredLink: '',
        sponsor: '',
        altTextCover: '',
        date
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    console.log(values)

    const linkPage = translit(values.title)


    const uploadCover = (callback) => {
        if (serviceCover == null) return;
        const storageRef = ref(storage, `blog/${Date.now()} + ${serviceCover.name}`) // ссылка на storage и папка
        const uploadTask = uploadBytesResumable(storageRef, serviceCover)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    callback(downloadURL)
                });
            }
        )
    }

    const uploadImages = () => {
        serviceImages.map(file => {
            const storageRef = ref(storage, `blog/${Date.now()} + ${file.name}`) // ссылка на storage и папка
            const uploadTask = uploadBytesResumable(storageRef, file)
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress)
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;

                    }
                },
                (error) => { console.log(error) },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setServiceImagesURL((prevState) => [...prevState, downloadURL]))

                }

            )
        })
    }

    // ADD FIRESTORE


    const colRef = collection(db, 'blog')
    const newPost = (url) => {
        setLoading(true)
        addDoc(colRef,
            { ...values, cover: url, link: linkPage, content }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        uploadCover((url) => {
            newPost(url)
        })
        setSubmitted(true)
    }

    //EDITOR

    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                // [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button

            ]
        }
    }
    const formats = [
        // 'background',
        'bold',
        // 'color',
        // 'font',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'block',
        'image',
        'video',
    ]

    return (
        <>
            <Header />
            <main>
                <form onSubmit={handleSubmit}>
                    <div className={style.form}>
                        <SelectGetID
                            label='Раздел'
                            options={blogSection}
                            onChange={(id) => (setValues({ ...values, section: id }))}
                        />
                        <Input
                            {...title}
                            value={values[title?.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...altTextCover}
                            value={values[altTextCover?.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...sponsor}
                            value={values[sponsor?.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...sponsoredLink}
                            value={values[sponsoredLink?.name]}
                            onChange={onChange}
                        />
                        <InputFile
                            {...cover}
                            selectedFile={serviceCover}
                            setSelectedFile={setServiceCover}
                        />

                        <div className={style.contentEditor}>
                            < ReactQuill
                                ref={editor}
                                value={content}
                                onChange={e => setContent(e)}
                                theme='snow'
                                modules={modules}
                                formats={formats}
                            />
                        </div>
                        <button disabled={loading} >Отправить</button>
                    </div>
                </form>

            </main>

        </>
    );
};