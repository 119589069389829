import React from 'react';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom'



export const RequireAuth = ({ children }) => {

    const { user } = useAuth()

    if (!user) {
        return <Navigate to='/login' />
    }
    return children
}