import React from 'react';
import style from './Blog.module.css'
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { CompanyForm } from '../components/Form/Company';
import { CardBlog } from '../components/Card/Card';
import { useGetDoc } from '../Hook/useGetDoc';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { Content } from '../components/Layout/Content/Content'
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';



const companyForm = <CompanyForm />

export const Blog = () => {

    const { docs } = useGetDoc('blog')


    const scrollBottom = () => {
        var body = document.body
        body.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    return (
        <>
            <Header />

            <main>
                <Breadcrumbs levelTwo={'Блог компании'} levelTwoURL='blog' />

                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}

                >

                    <FirstBlock
                        title={'Блог компании'}
                        lider={'Stories from the community powering the internets visuals'}
                        img={'/IconIllustration/blogImg.png'}
                    />
                </Content>

                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}

                >
                    <div className={style.grid}>
                        {docs.map(info => <CardBlog info={info} key={info.id} sectionLink={'blog'} />)}
                    </div>
                </Content>

            </main>

            <Footer />
        </>

    );
};