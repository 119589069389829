import React from 'react';
import { useState } from 'react'
import { addDoc, collection } from "firebase/firestore";
import { db } from '../../firebase-config';
import style from './AnketaPromalp.module.css'
import { Button } from '../Button';
import { Modal } from '../Modal';

import { Input } from './unitForm/input';
import { Checkbox, CheckboxPersonalData } from './unitForm/checkAndRadio';
import { InputFile } from './unitForm/inputFile';

import { formData } from './formData'

import { ref, getDownloadURL, uploadBytesResumable } from '@firebase/storage';
import { storage } from '../../firebase-config';



export const AnketaPromalpForm = () => {

    const { city, name, phone, email, privyaz, spuskSystem, strahSystem, personalData, fileUdo, udostoverenie, komandirovka } = formData

    const [submitted, setSubmitted] = useState(false)
    const [vahta, setVahta] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null)
    const [fileURL, setFileURL] = useState([])

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const [values, setValues] = useState({
        city: '',
        name: '',
        phone: '',
        email: '',
        privyaz: '',
        spuskSystem: '',
        strahSystem: '',
        udostoverenie: '',
        vahta: '',
        date,
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const uploadFile = (selectedFile) => {
        // if (selectedFile == null) return;
        const storageRef = ref(storage, `/public/PromalpManDocs/${Date.now()} + ${selectedFile.name}`) // ссылка на storage и папка
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFileURL(downloadURL)
                    setLoading(false)
                });
            }
        )
    }

    const peopleCleaningData = collection(db, 'Anketa') // передаем коллекцию firebase
    const newOrder = (url) => {
        setLoading(true)
        addDoc(peopleCleaningData,
            { ...values, vahta, file: fileURL }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        newOrder()
        setSubmitted(true);
        setTimeout(function () {
            window.location.reload();
        }, 3000);
    }


    return (
        <>
            {submitted
                ?
                <Modal loading={loading} setActive={setSubmitted} submitted={submitted}>
                    <div className='modal-img'>
                        <img src="/img/zayavka_plain.png" alt="" />
                    </div>
                    <h3>Заявка отправлена</h3>
                    <p>Мы свяжемся с вами в ближайшее время</p>
                </Modal>

                : null}

            <form className={style.form} onSubmit={handleSubmit}>

                <div className={style.inputContainer}>
                    <h3>Анкета промальпа</h3>
                    <div className={style.inputs}>

                        <Input
                            {...name}
                            value={values[name.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...city}
                            value={values[city.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...phone}
                            value={values[phone.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...email}
                            value={values[email.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...privyaz}
                            value={values[privyaz.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...spuskSystem}
                            value={values[spuskSystem.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...strahSystem}
                            value={values[strahSystem.name]}
                            onChange={onChange}
                        />
                        <Input
                            {...udostoverenie}
                            value={values[udostoverenie.name]}
                            onChange={onChange}
                        />
                    </div>
                    <InputFile
                        {...fileUdo}
                        uploadFile={uploadFile}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}

                    />
                </div>
                <div className={style.button}>
                    <div className={style.checkbox}>
                        <Checkbox
                            {...komandirovka}
                            checked={vahta}
                            onChange={e => setVahta(e.target.checked)}
                        />
                        <CheckboxPersonalData />
                    </div>
                    <Button
                        type="submit"
                        buttonStyle={"btn--blue"}
                        buttonSize={"btn--m"}
                        IconRight={'arrowR'}
                        IconColor={'white'}
                    >Отправить
                    </Button>

                </div>
            </form>
        </>
    );
};