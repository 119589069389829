import React from 'react';
import style from './PeopleCleaning.module.css'

import { Select } from './unitForm/select';
import { Input } from './unitForm/input';
import { Checkbox, CheckboxPersonalData } from './unitForm/checkAndRadio';

import { useState, useEffect } from 'react'
import { Button } from '../Button';
import { addDoc, collection } from "firebase/firestore";
import { db } from '../../firebase-config';
import { Modal } from '../Modal';
import { formData } from './formData'
import { region } from './formData'
import { time } from './formData'





export const PeopleCleaning = ({ idCheckboxDirt, idCheckboxPersonData }) => {

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const { residentArea, address, frontDoor, floor, window, windowSquare, balconWindowSquare, name, phone, checkDirt, radioYear, radioYears, radioNever, personalData } = formData

    const [dirt, setDirt] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [personData, setPersonData] = useState(false)

    const [values, setValues] = useState({
        region: '',
        residentArea: '',
        address: '',
        frontDoor: '',
        floor: '',
        window: '',
        windowSquare: '',
        balconWindowSquare: '',
        name: '',
        phone: '',
        date,
        time: '',
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }


    const data = collection(db, 'PeopleServicesCleaning')
    const newOrder = () => {
        setLoading(true)
        addDoc(data,
            { ...values, dirt, price }
        )
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        newOrder()
        setSubmitted(true);
    }


    const [price, setPrice] = useState(0)

    useEffect(() => {
        let basePrice = 400;
        if (dirt) {
            basePrice *= 1.5
        } else if (values.time === 'До 3 лет' || values.time === 'Никогда') {
            basePrice *= 1.25
        }
        const wSq = Number(values.windowSquare ? values.windowSquare : 0),
            windowsCount = Number(values.window),
            balconsSquare = Number(values.balconWindowSquare ? values.balconWindowSquare : 0);
        setPrice((wSq * windowsCount + balconsSquare) * basePrice || 0)
    }, [values, dirt])



    function validate(evt) {
        var theEvent = evt || window.event;
        // Handle paste
        if (theEvent.type === 'paste') {
            key = window.event.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    console.log(personData)


    return (
        <>
            {submitted ? <Modal loading={loading} setActive={setSubmitted} submitted={submitted} /> : null}

            <form onSubmit={handleSubmit}>
                <div className={style.form} >
                    <div className={style.inputContainer}>
                        <div className={style.title}>
                            <h3>Моем окна на любом этаже</h3>
                            <p>Калькулятор стоимости. Мы свяжемся с вами, чтобы уточнить детали перед выполнением услуги</p>
                        </div>
                        <div className={style.inputs}>
                            <Select
                                label='Выберите район'
                                options={region}
                                onChange={(reg) => (
                                    setValues({ ...values, region: reg })
                                )}
                            />
                            <Input
                                {...residentArea}
                                value={values[residentArea.name]}
                                onChange={onChange}
                            />

                            <div className={`${style.dubleItem} ${style.address}`}>
                                <Input
                                    {...address}
                                    value={values[address.name]}
                                    onChange={onChange}
                                />
                                <Input
                                    {...frontDoor}
                                    value={values[frontDoor.name]}
                                    onChange={onChange}
                                />
                            </div>
                            <div className={style.dubleItem}>
                                <Input
                                    {...floor}
                                    value={values[floor.name]}
                                    onChange={onChange}
                                />
                                <Input
                                    {...window}
                                    value={values[window.name]}
                                    onChange={onChange}
                                    onKeyPress={validate}
                                />
                                <Input
                                    {...windowSquare}
                                    value={values[windowSquare.name]}
                                    onChange={onChange}
                                    onKeyPress={validate}
                                />
                            </div>
                            <Input
                                {...balconWindowSquare}
                                value={values[balconWindowSquare.name]}
                                onChange={onChange}
                                onKeyPress={validate}
                            />
                            <div className={style.dubleItem}>
                                <Input
                                    {...name}
                                    value={values[name.name]}
                                    onChange={onChange}
                                />
                                <Input
                                    {...phone}
                                    value={values[phone.name]}
                                    onChange={onChange}
                                />
                            </div>
                            <Select
                                label='Когда мыли окна в последний раз'
                                options={time}
                                onChange={(time) => (
                                    setValues({ ...values, time })
                                )} />
                        </div>
                        <div className={style.checkbox}></div>
                        <Checkbox
                            {...checkDirt}
                            id={idCheckboxDirt}
                            checked={dirt}
                            onChange={e => setDirt(e.target.checked)}
                        />
                    </div>
                    <div className={style.priceContainer}>
                        <h3> Итого: {price} руб</h3>
                        <CheckboxPersonalData id = {idCheckboxPersonData } />
                        <Button
                            type="submit"
                            buttonStyle={"btn--blue"}
                            buttonSize={"btn--m"}
                            IconRight={'arrowR'}
                            IconColor={'white'}
                        >Отправить
                        </Button>
                    </div>
                </div>

            </form>
        </>

    );
};


{/* {inputs.map((input) => (
                    <Input
                        {...input}
                        key={input.id}
                        value={values[input.name]}
                        onChange={onChange}
                    />
                ))} */}






// const handleRadio = e => {
//     const { value } = e.target;
//     setValues({ ...values, time: value })
// }



{/* <div className={style.radio}> */ }

{/* <div className={style.radioSet}>
                                        <Radiobutton

                                            type='radio'
                                            name='time'
                                            id='year'
                                            value="year"
                                            label="От года"
                                            onChange={handleRadio}
                                        />
                                        <Radiobutton

                                            type='radio'
                                            name='time'
                                            id='years'
                                            value='3years'
                                            label="От 3 лет"
                                            onChange={handleRadio}
                                        />
                                        <Radiobutton

                                            type='radio'
                                            name='time'
                                            id='never'
                                            value='never'
                                            label="Никогда"
                                            onChange={handleRadio}

                                        />
                                    </div> */}
{/* </div> */ }