import React from 'react';
import { useParams } from 'react-router-dom';
import style from './Singlepage.module.css'
import { SliderImage } from '../components/SliderImage/sliderImage';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { FormBlock } from '../components/Form/formBlock'
import { CompanyForm } from '../components/Form/Company'
import { useGetDoc } from '../Hook/useGetDoc';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { Content } from '../components/Layout/Content/Content';
import { CardServiceOther } from '../components/Card/Card';

import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import { SalePeople } from '../components/Banners/SalePeople';
import { UborkaSnega } from '../Price/Price';
import { ServiceSectionContext } from '../Context/ServicesSectionContext';
import { localDataService } from '../Data/LocalDataService';
import { Button } from '../components/Button';



export const CompanyServiceSinglepage = () => {

    const { docs } = useGetDoc('services')

    const { link } = useParams()
    const service = docs.find((service) => service?.link === link)

    // const { serviceSection } = ServiceSectionContext()
    // const section = serviceSection.find(unit => unit.id === service?.category)

    const localData = localDataService.find(unit => unit.name === service?.name)

    return (
        <>
            <Header />

            <main>

                <Breadcrumbs levelTwo={'Услуги'} levelTwoURL='company-service' levelThree={service?.serviceName} />

                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.grid}>
                        <div className={style.title}>
                            <h2>{service?.title}</h2>
                        </div>

                        <div className={style.content}>
                            {service?.images
                                ? <div className={style.sliderImages}>
                                    <SliderImage imgs={service?.images} />
                                </div>
                                : null
                            }

                            <Content
                                contentMargin={'cnt-margin-xxs'}
                            >
                                <div className={style.textStyle}>
                                    <div dangerouslySetInnerHTML={{ __html: service?.content }}></div>
                                </div>

                            </Content>


                            <Content
                                contentMargin={'cnt-margin-xxs'}
                            >
                                {localData
                                    ? localData?.price
                                    : <a href='/price'>
                                        <Button
                                            buttonStyle={"btn--green"}
                                            buttonSize={"btn--m"}
                                            IconRight={'arrowR'}
                                            IconColor={'var(--white)'}
                                        >Тарифы и цены на услуги
                                        </Button>
                                    </a>
                                }
                            </Content>

                            <Content
                                contentMargin={'cnt-margin-xxs'}
                            >
                                <SalePeople
                                    title={'Свяжитесь с нами'}
                                    description={'Хотите обсудить детали оказания услуги или узнать цену? Закажите обратный звонок или перейдите в форму заявки, чтобы отправить техническое задание и получить расчет стоимости.'}
                                    button2={'Заказать звонок'}
                                    button3={'Форма заявки'}
                                    button3href={"/order-form"}
                                // image={'/IconIllustration/banner_sale.png'}
                                />
                            </Content>


                        </div>

                        <div className={style.rightBlock}>

                            <div className={style.otherService}>
                                <h6>Все услуги для компаний</h6>

                                {docs
                                    .filter(unit => unit.client === 'company')
                                    .map(service => <CardServiceOther url='company-service' service={service} key={service.id} />)
                                }
                            </div>

                        </div>

                    </div>
                </Content>

            </main>

            <Footer />
        </>



    );
};