import React from 'react';
import { ScrollLeft } from '../ScrollHorizontal/ScrollHorizontal';
import { ScrollRight } from '../ScrollHorizontal/ScrollHorizontal';
import { FeedbackCard } from './feedbackCard';
import style from './feedback.module.css'

export const FeedbackBlock = ({feedbacks}) => {
    return (

        <div className={style.feedbackContainer}>
            <div className={style.feedbackContent}>
                <h1>Отзывы</h1>
                <div className={style.containerScroll}>
                    <ScrollLeft id='feedback-scroll' />
                    <div className={style.horizontalScroll} id='feedback-scroll'>
                        {feedbacks.map(feedback => <FeedbackCard feedback={feedback} key={feedback.id} />)}
                    </div>
                    <ScrollRight id='feedback-scroll' />
                </div>
            </div>
        </div>

    );
};
