import React from 'react';
import './modal.css'

export const Modal = ({ children, loading, setActive, submitted }) => {

    const cancel = () => {
        setActive(false)
        setTimeout(function () {
            window.location.reload();
        });

    }


    return (
        <div className='modal-back active' onClick={() => setActive(false)}>
            <div className='modal-container' onClick={(e) => e.stopPropagation()}>


                {loading
                    ? <div>Loading</div>
                    :
                    <>
                        {submitted
                            ?
                            <>
                                <div className='feedback'>
                                    <img src="/IconIllustration/BIGCheck.png" alt="" />
                                    <h3>Заявку приняли</h3>
                                    <p>Мы с вами свяжемся в ближайшее время</p>
                                </div>
                                <div className='cancel' onClick={cancel}>
                                    <img src="/IconIllustration/Cancel.svg" alt="" />
                                </div>
                            </>

                            :
                            <div className='modal-content'>{children}</div>
                        }
                    </>
                }
                <div className='cancel' onClick={() => setActive(false)}>
                    <img src="/IconIllustration/Cancel.svg" alt="" />
                </div>
            </div>
        </div>
    );
};

