import React, { useRef } from 'react';
import style from './component.module.css'
import { useState } from 'react';

export const Checkbox = (props) => {

    const
        {
            id,
            label,
            errorMessage,
            onChange,
            type,
            checked,
            ...inputProps
        } = props

    return (
        <div className={style.checkbox}>
            <label htmlFor={id} className={style.checkLabel}>
                <input className={style.defaultCheckbox}
                    {...inputProps}
                    id={id}
                    checked={checked}
                    type={type}
                    onChange={onChange}
                />
                <span className={style.newCheckbox} ></span>
                {label}
            </label>
            <span className={style.errorMessage}>{errorMessage}</span>

        </div>
    );
};

export const CheckboxPersonalData = ({id}) => {

    const [personData, setPersonData] = useState(false)

    return (
        <div className={style.checkbox}>
            <label htmlFor={id ? id : 'personalData'} className={style.checkLabel}>
                <input className={style.defaultCheckboxData}
                    id={id ? id : 'personalData'}
                    type='checkbox'
                    onChange={(e) => setPersonData(!e.target.checked)}
                    required
                />
                <span className={style.newCheckbox} ></span>
                Согласен на обработку данных
            </label>
            {personData &&
                <span className={style.errorMessagePersonData}>
                    Нужно ваше согласие
                </span>
            }

        </div>
    );
};


export const Radiobutton = ({ id, value, onChange, type, name, label }) => {

    return (
        <div className={style.radio}>
            <label htmlFor={id} className={style.checkLabel}>
                <input className={style.defaultRadio}
                    id={id}
                    type={type}
                    onChange={onChange}
                    name={name}
                    value={value}
                />
                <span className={style.newRadio} ></span>
                {label}
            </label>
        </div>
    );
};

