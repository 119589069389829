import React, { useState } from 'react';
import style from './Authentication.module.css'

import { formData } from '../components/Form/formData'
import { Input } from '../components/Form/unitForm/input';
import { Button } from '../components/Button';

import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext';
import { Header } from '../components/HeaderFooter/header';




export const Login = () => {

    const { email, password } = formData
    const { logIn } = useAuth()

    const [emailUser, setEmailUser] = useState('')
    const [passwordUser, setPasswordUser] = useState('')
    const [error, setError] = useState()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        try {
            await logIn(emailUser, passwordUser) //ждем завершения регистрации
            navigate('/admin')
        } catch (error) {
            setError(error.message)
            console.log(error.message)
        }
    }

    return (
        <>
            <Header/>

            <main>
                <form>
                    <div className={style.container}>
                        <div className={style.content}>
                            <Input
                                {...email}
                                value={emailUser}
                                onChange={e => setEmailUser(e.target.value)}
                            />
                            <Input
                                {...password}
                                value={passwordUser}
                                onChange={e => setPasswordUser(e.target.value)}
                            />
                            <Button
                                type="button"
                                buttonStyle={"btn--blue"}
                                buttonSize={"btn--s"}
                                onClick={handleSubmit}
                            >Вход
                            </Button>
                        </div>
                        {/* <p>Нет аккаунта?<Link to='/signup'> Зарегистрироваться </Link></p> */}

                    </div>
                    <div>{error}</div>
                </form>


            </main>





        </>

    );
};


// const register = async () => {
//     try {
//         const user = await createUserWithEmailAndPassword(auth, emailReg, passwordReg)
//         console.log(user)
//     } catch (error) {
//         console.log(error.message)

//     }
// }

// const [user, setUser] = useState(null)

//     onAuthStateChanged(auth, (currentUser) => {
//         setUser(currentUser)
//     })

//     const login = async () => {
//         // setUser(user)
//         try {
//             const user = await signInWithEmailAndPassword(auth, emailLogin, passwordLogin)
//             console.log(user)
//         } catch (error) {
//             console.log(error.message)
//         }
//     }

//     const logout = async () => {
//         await signOut(auth)
//         // setUser(null)
//     }

//     const navigate = useNavigate()

//     const handleLogin = () => {
//         auth.login(emailLogin, passwordLogin)
//         navigate('/admin/people-cleaning-form')


{/* <form>
                <div className={style.container}>
                    <div className={style.content}>
                        <Input
                            {...email}
                            value={emailReg}
                            onChange={(e) => setEmailReg(e.target.value)}
                        />
                        <Input
                            {...password}
                            value={passwordReg}
                            onChange={(e) => setPasswordReg(e.target.value)}
                        />
                        <Button
                            type="button"
                            buttonStyle={"btn--blue"}
                            buttonSize={"btn--s"}
                            onClick={login}
                        >Регистрация
                        </Button>

                    </div>
                </div>
            </form> */}