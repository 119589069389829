import React, { useEffect } from 'react';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import style from './contact.module.css';
import { Button, Phone, Telegram, WhatsApp } from '../components/Button/button';

const map = {

}

const employees = [
    {
        name: 'Гайфуллин Ильшат Рафаэлевич',
        function: 'Генеральный директор OOO Высшая лига',
        phone: ['8 962 812-0000'],
        email: 'ceo@ligapromalp.com',
        photo: '/img/contact_ilshat.jpg',
        whatsapp: 'https://wa.me/79628120000',
        telegram: 'https://t.me/vishayliga13',
        call: 'tel:+79628120000',

    },
    {
        name: 'Мингажев Раиль Шамилевич',
        function: 'Руководитель отдела продаж',
        phone: ['8 915 334-82-80'],
        email: 'rail@ligapromalp.com',
        photo: '/IconIllustration/photoMan.png',
        whatsapp: 'https://wa.me/79658121010',
        call: 'tel:+79658121010',

    },
    {
        name: 'Питерская Елена Викторовна',
        function: 'Менеджер по продажам',
        phone: ['8 965 812-10-10'],
        email: '',
        photo: '/IconIllustration/photoWoman.png',
        whatsapp: 'https://wa.me/79111377691',
        call: 'tel:+79111377691',

    },
]

export const Contact = () => {


    return (

        <>
            <Header />

            <main className={style.main}>
                <div className={style.content}>
                    <h2>Контакты</h2>

                    <div className={style.contacts}>

                        <div className={style.hotСall}>
                            {/* <div>
                                <img src="/IconIllustration/hotCall.svg" style={{ width: '24px' }} />
                                <p>  8 800 777-42-61 (звонок по России бесплатный)</p>
                            </div> */}
                            <div>
                                <img src="/IconIllustration/Times.svg" />
                                <p>Принимаем заявки с 10:00–22:00</p>
                            </div>
                        </div>

                        <div className={style.contact}>

                            <div className={style.map}>
                                <iframe className={style.mapsize} src="https://yandex.ru/map-widget/v1/?um=constructor%3A80393b5cb3cff9057d5c51a3d75ddb743bd704e650202a495abb8ecdfdd7bb0e&amp;source=constructor" width="400" height="280" frameborder=""></iframe>
                                <p className={style.address}>
                                    190000 Санкт-Петербург, муниципальный округ Сампсониевское, проспект Большой Сампсониевский, 66А, стр 1, пом 11-Н, ком. 4
                                </p>
                            </div>

                            <div className={style.personals}>
                                {employees.map(emp => <Personal emp={emp} key={emp.name} />)}
                            </div>



                        </div>
                    </div>

                </div>
            </main>

            <Footer />
        </>
    );
};


const Personal = ({ emp }) => {
    return (
        <div className={style.personal}>

            <div className={style.photo}><img src={emp.photo} /></div>
            <div className={style.person}>
                <p className={style.personalText}>
                    <span>{`${emp.name}`}</span> <br />
                    {`${emp.function}`} <br />
                    {`${emp.phone.join(', ')}`} <br />
                    {emp.email}
                </p>
                <div className={style.messenger}>
                    {emp?.call ? <Phone tel={emp?.call} /> : null}
                    {emp?.whatsapp ? <WhatsApp link={emp?.whatsapp} /> : null}
                    {emp?.telegram ? <Telegram link={emp?.telegram} /> : null}
                </div>
            </div>

        </div>
    )
}
