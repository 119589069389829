import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import style from './component.module.css'
// import onClickOutside from 'react-onclickoutside'

export const SelectGetID = ({ options, onChange, id, label, ...selectProps }) => {

    const [selected, setSelected] = useState('')
    const [active, setActive] = useState(false)


    return (

        <>
            <div className={style.select}>
                <div className={style.selectLabel}>{label}</div>
                <div className={style.selectInput} onClick={() => setActive(!active)}>
                    <span className={style.selectCurrent}>{selected}</span>
                    <div className={style.selectIcon}></div>
                </div>

                {active && (
                    <div className={style.dropdown}>
                        {options?.map(option => (
                            <div key={option.id}
                                className={style.selectItem}
                                onClick={() => {
                                    setActive(!active)
                                    setSelected(option.name)
                                    onChange(option.id)
                                }}
                            >
                                {option.name}
                            </div>
                        ))
                        }
                    </div >
                )}
            </div>
        </>

    )
}
