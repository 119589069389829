import React from 'react';
import style from './Services.module.css'
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { CompanyForm } from '../components/Form/Company';
import { CardService } from '../components/Card/Card';
import { useGetDoc } from '../Hook/useGetDoc';
import { Breadcrumbs } from '../components/Bread/Breadcrumbs';
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';
import { Content } from '../components/Layout/Content/Content'
import { ScrollToBlock } from '../components/Helper/ScrollTo';
import { useRef } from 'react';



export const CompanyServices = ({ services }) => {

    const { docs } = useGetDoc('services')
    const form = useRef()


    return (
        <>
            <Header />


            <main>
                <Breadcrumbs levelTwo={'Услуги'} levelTwoURL='company-service' />
                <Content
                    contentMargin={'cnt-margin-s'}
                    contentWidth={'cnt-width-m'}
                >
                    <FirstBlock
                        title={'Услуги промышленного альпинизма'}
                        lider={'Услуги для компаний, УК, ЖК от надежного лидера рынка промышленного альпинизма'}
                        button={'Заказать звонок'}
                        img={'/IconIllustration/companyService_sotrudnichestvo.png'}
                        onClick={() => ScrollToBlock(form)}
                    />
                </Content>

                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.services}>

                        <h2>Обслуживание и ремонт зданий</h2>
                        <div className={style.service}>
                            {docs
                                .filter(unit => unit.category === 'buildingService')
                                .map(service => <CardService url='company-service' service={service} key={service.id} />)
                            }</div>

                        <h2>Монтаж / Демонтаж</h2>
                        <div className={style.service}>

                            {docs
                                .filter(unit => unit.category === 'montage')
                                .map(service => <CardService url='company-service' service={service} key={service.id} />)
                            }</div>

                        <h2>Услуги для жителей</h2>
                        <div className={style.service}>
                            {docs
                                .filter(unit => unit.client === 'people')
                                .map(service => <CardService url='people-service' service={service} key={service.id} />)
                            }</div>

                    </div>
                </Content>

            </main>

            <Footer />
        </>
    );
};