import React from 'react';
import style from './sliderImage.module.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { ScrollHorizontal } from '../ScrollHorizontal/ScrollHorizontal';

export const SliderImage = ({ imgs }) => {

    const [index, setIndex] = useState(0)

    useEffect(() => {
        setIndex(0)
    }, [])

    const next = () => {
        if (index === imgs.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }
    const prev = () => {
        if (index === 0) {
            setIndex(imgs.length - 1)
        } else {
            setIndex(index - 1)
        }
    }

    return (
        <>
            {/* <div className={style.slider}> */}
            <div className={style.slide}>
                <img className={style.currentImage} src={imgs[index]} />
                <div className={style.buttons}>
                    <div className={style.colorButton}><div className={style.buttonLeft} onClick={prev} /></div>
                    <div className={style.colorButton}><div className={style.buttonRight} onClick={next} /></div>
                </div>
            </div>
            <ScrollHorizontal>
                <div className={style.preview}>
                    {imgs.map((imgsrc, i) => (
                        <img
                            key={i}
                            src={imgsrc}
                            onClick={() => setIndex(i)}
                            className={index === i ? `${style.active}` : ''}
                        />
                    ))}
                </div>
            </ScrollHorizontal>
            {/* </div> */}
        </>
    );
};