import React, { useEffect } from 'react';
import style from './GetOrderForms.module.css'
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useState } from 'react';
import { useGetDoc } from '../../Hook/useGetDoc';


export const GetCallCollection = () => {


    const { docs } = useGetDoc('CallOrder')
    console.log('docs',docs)

    return (
        <div>
            <h3 className={style.title}>Перезвонить</h3>
            <table className={style.table}>
                <tr>
                    <th>Дата</th>
                    <th>Имя</th>
                    <th>Телефон</th>
                </tr>

                {docs.map((item) => (
                    <tr className={style.tableItem} key={item?.id} >
                        <td style={{ textAlign: 'center' }}>{item?.date}</td>
                        <td style={{ textAlign: 'center' }}>{item?.name}</td>
                        <td style={{ textAlign: 'center' }}>{item?.phone}</td>
                    </tr>
                ))}

            </table>
        </div>
    );
};
