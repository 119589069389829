import React, { useEffect } from 'react';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import style from './OrderForm.module.css';
import { Phone, Telegram, WhatsApp } from '../components/Button/button';
import { CompanyForm } from '../components/Form/Company';
import { FirstBlock } from '../components/Layout/FirstBlock/FirstBlock';
import { Content } from '../components/Layout/Content/Content';

const map = {

}

const employees = [
    {
        name: 'Гайфуллин Ильшат Рафаэлевич',
        function: 'Генеральный директор OOO Высшая лига',
        phone: ['8 962 812-0000'],
        email: 'ceo@ligapromalp.com',
        photo: '/img/contact_ilshat.jpg',
        whatsapp: 'https://wa.me/79628120000',
        telegram: 'https://t.me/vishayliga13',
        call: 'tel:+79628120000',

    },
    {
        name: 'Мингажев Раиль Шамилевич',
        function: 'Руководитель отдела продаж',
        phone: ['8 915 334-82-80'],
        email: 'rail@ligapromalp.com',
        photo: '/IconIllustration/photoMan.png',
        whatsapp: 'https://wa.me/79658121010',
        call: 'tel:+79658121010',

    },
    {
        name: 'Питерская Елена Викторовна',
        function: 'Менеджер по продажам',
        phone: ['8 965 812-10-10'],
        email: '',
        photo: '/IconIllustration/photoWoman.png',
        whatsapp: 'https://wa.me/79111377691',
        call: 'tel:+79111377691',

    },
]

export const OrderForm = () => {


    return (

        <>
            <Header />

            <main className={style.main}>
                <Content
                    backgroundColor={'var(--bg-darkBlue)'}
                    backgroundPadding={'bg-padding-s'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}

                >
                    <FirstBlock
                        title={'Форма заявки'}
                        lider={`Используйте эту форму, чтобы связаться с нашей командой. Если у вас есть срочный вопрос, пожалуйста, напишите нам в WhatsApp.`}
                        whiteText={true}
                        img={'/IconIllustration/FAQ.png'}

                        button={'Whats App'}
                        IconRight={'whatsapp'}
                        IconColor={'white'}
                        buttonStyle={"btn--green"}
                        buttonUrl={'https://wa.me/79628120000'}
                    />
                </Content>


                <Content
                    backgroundColor={"var(--grey)"}
                    backgroundPadding={'bg-padding-s'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.form}>
                        <CompanyForm />
                    </div>
                </Content>

            </main>

            <Footer />
        </>
    );
};

