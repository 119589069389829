import React from 'react';
import { Footer } from '../components/HeaderFooter/footer';
import { Header, HeaderTransparent } from '../components/HeaderFooter/header';
import style from "./ligaDobra.module.css"

import { CardInformation, CardProject } from '../components/Card/Card';
import { useGetDoc } from '../Hook/useGetDoc';
import { FirstBlockHomepageStyle } from '../components/Layout/FirstBlock/FirstBlock';
import { Content } from '../components/Layout/Content/Content'



export const LigaDobra = () => {

    const { docs } = useGetDoc('projects')
    const philanthrop = docs.filter(unit => unit.philanthropy)
    console.log(philanthrop)

    return (
        <>


            <Header />

            <main>

                <FirstBlockHomepageStyle
                    image={'/img/liga-dobra_cover.jpg'}
                    title={'Лига добра'}
                    backgroundColor={'var(--bg-darkBlue)'}
                    lider=
                    {'Благодаря нашим клиентам, мы помогаем поддерживать чистоту остеклений местных городских учреждений. В 2021 году мы собрали более 200 000 рублей на поддержку проекта Лига добра, благодаря жителям из Санкт-Петербурга которые доверили нам мытье своих окон. Каждый раз, когда вы заказываете услугу, мы жертвуем 7% на поддержку нашей программы Лига добра'}
                />


                <Content
                    backgroundColor={'var(--grey)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.content}>
                        {philanthrop.map(project => <CardProject project={project} key={project.link} url={'projects'} />)}
                    </div>
                </Content>


            </main>
            <Footer />
        </>
    );
};