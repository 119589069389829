import { AButton } from '../components/Button'
import React from 'react';
import style from './SearchPromalpPage.module.css'
import { PlusCard } from '../components/PlusCard/PlusCard';
import { Header } from '../components/HeaderFooter/header';
import { Footer } from '../components/HeaderFooter/footer';
import { SearchPromAlplForm } from '../components/Form/SearchPromalp';
import { Content } from '../components/Layout/Content/Content'
import { FirstBlockHomepageStyle } from '../components/Layout/FirstBlock/FirstBlock';
import { ScrollToBlock } from '../components/Helper/ScrollTo';
import { useRef } from 'react';




const cennosti = [
    {
        icon: '/IconIllustration/obsuzhdenie_proekta.jpg',
        title: 'Обсуждение проекта',
        description: 'Определение ваших индивидуальных потребностей, чтобы мы могли быть вашим идеальным кадровым партнером.',
        id: 1,
    },
    {
        icon: '/IconIllustration/poisk_ispolnitelya.jpg',
        title: 'Поиск исполнителей',
        description: 'Найдем доступных кандидатов из местного сообщества или из других регионов, используя нашу эксклюзивную базу промышленных альпинистов',
        id: 1,
    },
    {
        icon: '/IconIllustration/vibor_ispolnitelei.jpg',
        title: 'Выбор исполнителей',
        description: 'Мы проверяем соответствие удостоверений, набор навыков и квалификаций для возможности безопасной работы и обеспечения высокого уровня качества предоставляемой услуги',
        id: 1,
    },
    {
        icon: '/IconIllustration/proizvoditelnost.jpg',
        title: 'Мониторинг производительности',
        description: 'Наши специалисты оцениваются заказчиками после сотрудничества и содержат отчеты об оценке исполнения. Специалисты, получающие отрицательную оценку, далее не имеют возможности сотрудничать с заказчиками через нашу компанию',
        id: 1,
    },
]

const formSearchPromalpl = <SearchPromAlplForm />




export const SearchPromalp = () => {

    const form = useRef()


    return (
        <>
            <Header />

            <main>

                <FirstBlockHomepageStyle
                    image={'/img/search_promalp.jpeg'}
                    title={'Поиск исполнителей'}
                    lider={'Мы предлагает своим клиентам, возможность найма персонала непосредственно из нашей базы промышленных альпинистов. Мы используем свою эксклюзивную сеть, чтобы найти подходящего кандидата на выполнение работ.'}
                    backgroundColor={'var(--bg-darkBlue)'}
                    btn1={'Заполнить заявку'}
                    onClickBtn1={() => ScrollToBlock(form)}
                />

                <Content
                    contentMargin={'cnt-margin-m'}
                    contentWidth={'cnt-width-m'}
                >
                    <div className={style.howWorks}>
                        <h1>Как мы работаем</h1>
                        <div className={style.work}>
                            {cennosti.map(cennost => <PlusCard cennost={cennost} key={cennost.id} />)}
                        </div>
                    </div>
                </Content>


                <Content
                    backgroundColor={'var(--grey-dark)'}
                    backgroundPadding={'bg-padding-m'}
                    contentMargin={'cnt-margin-0'}
                    contentWidth={'cnt-width-m'}
                >
                    <div
                        className={style.form}
                        ref={form}
                    >
                        <SearchPromAlplForm />
                    </div>
                </Content>

            </main>

            <Footer />
        </>
    );
};
